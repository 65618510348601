import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Icon from '@mui/material/Icon'
import moment, { Moment } from 'moment'

import { RootState } from '../../../appReducer'
import { PatientType } from '../../../models/enums'
import { useAppDispatch } from '../../../util/useAppDispatch'
import { fetchAvailability, fetchLocationSchedulingData } from '../../practices/actions'
import { usePrevious } from '../../shared/custom-hooks'

import { selectAppointmentDay, setAppointmentDatetime, setAppointmentPickerError } from './actions'
import AppointmentPickerPreviewOverlay from './AppointmentPickerPreviewOverlay'
import CalendarPicker from './CalendarPicker'
import SchedulingErrorMessage from './SchedulingErrorMessage'

import './AppointmentPicker.sass'

const API_DATE_FORMAT = 'YYYY-MM-DD'
const ERROR_CANNOT_CONNECT = 'We couldn’t connect to the practice calendar.'

function calculateDisplayDays(numSelectedChats: number, numChatsOnScreen: number) {
    if (numSelectedChats >= 3 && numChatsOnScreen === 3) {
        return 3
    }
    if (numSelectedChats >= 2 && numChatsOnScreen === 2) {
        return 7
    }

    return 9
}

export type Props = {
    chat: Models.ChatMetadata
    tab: PatientType
    patientName: string
    location: Models.PracticeLocation
    procedure: Models.Procedure
    practice: Models.Practice
    isFormValid: boolean
    directSchedulingRef: React.RefObject<HTMLDivElement>
    providerId?: string
    selectedDate: Moment
    selectedDatetime?: Moment
    errorMessage?: string
    onBookAppointment: () => void
}

export type AppointmentPickerState = {
    startDate: Moment
    endDate: Moment
    minDate: Moment
    isLoading: boolean
}

const moduleName = 'scheduling-appointment-picker'

const AppointmentPicker = ({
    selectedDate,
    selectedDatetime,
    procedure,
    location,
    practice,
    providerId,
    chat,
    tab,
    patientName,
    isFormValid,
    directSchedulingRef,
    onBookAppointment,
}: Props) => {
    const isComponentMounted = useRef(true)

    const today = moment()
    const [startDate, setStartDate] = useState<Moment>(today)
    const [minDate] = useState<Moment>(today)
    const [endDate, setEndDate] = useState<Moment>(moment(today).add(2, 'days'))
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isCalendarShown, showCalendar] = useState<boolean>(true)
    const [timeslotError, setTimeslotError] = useState<boolean>(false)
    const [isPreviewAppointmentOverlayShown, showPreviewAppointmentOverlay] = useState<boolean>(false)

    const prevProcedure = usePrevious(procedure)
    const prevLocation = usePrevious(location)
    const prevSelectedDate = usePrevious(selectedDate)
    const availableSlots = procedure.availability[location.id]

    const selectedChats = useSelector((state: RootState) => state.chat.selectedChats)
    const numChatsOnScreen = useSelector((state: RootState) => state.chat.numChatsOnScreen)

    const DISPLAY_DAYS = calculateDisplayDays(selectedChats.length, numChatsOnScreen)

    const dispatch = useAppDispatch()

    const checkSchedule = useCallback(
        (day: Moment, refresh: Boolean = false) => {
            if (
                prevProcedure === procedure &&
                prevLocation === location &&
                prevSelectedDate === selectedDate &&
                !refresh
            ) {
                return false
            }
            dispatch(setAppointmentPickerError(chat, tab, undefined))
            dispatch(fetchLocationSchedulingData(practice, location))
            const startDay = day.format(API_DATE_FORMAT)

            if (day.isSame(moment(), 'day')) {
                const tomorrow = moment()
                    .add(1, 'days')
                    .format(API_DATE_FORMAT)
                return dispatch(
                    fetchAvailability(location, procedure, startDay, startDay, providerId, tomorrow),
                ).catch(() => dispatch(setAppointmentPickerError(chat, tab, ERROR_CANNOT_CONNECT)))
            } else {
                return dispatch(fetchAvailability(location, procedure, startDay, startDay, providerId)).catch(() =>
                    dispatch(setAppointmentPickerError(chat, tab, ERROR_CANNOT_CONNECT)),
                )
            }
        },
        [
            prevProcedure,
            procedure,
            prevLocation,
            location,
            prevSelectedDate,
            selectedDate,
            dispatch,
            chat,
            tab,
            practice,
            providerId,
        ],
    )

    const getAvailableDays = useCallback(
        (start: Moment, end: Moment) => {
            return dispatch(
                fetchAvailability(
                    location,
                    procedure,
                    start.format(API_DATE_FORMAT),
                    end.format(API_DATE_FORMAT),
                    providerId,
                ),
            )
        },
        [dispatch, location, procedure, providerId],
    )

    useEffect(() => {
        if (availableSlots?.[endDate.format(API_DATE_FORMAT)] === undefined) {
            getAvailableDays(moment(endDate).subtract(DISPLAY_DAYS, 'days'), endDate)
        }
    }, [availableSlots, endDate, getAvailableDays])

    useEffect(() => {
        if (isPreviewAppointmentOverlayShown && directSchedulingRef.current) {
            directSchedulingRef.current.scrollBy({ top: 500, behavior: 'smooth' })
        }
    }, [isPreviewAppointmentOverlayShown, directSchedulingRef])

    useEffect(() => {
        isComponentMounted.current = true
        return () => {
            isComponentMounted.current = false
        }
    }, [])

    const renderMonth = () => {
        const sameMonth = startDate.month() === endDate.month()
        const thisYear = endDate.year() === moment().year()
        let result = startDate.format('MMMM')

        if (!sameMonth) {
            const sameYear = startDate.year() === endDate.year()

            if (!sameYear) {
                result += startDate.format(', YYYY')
            }

            result += endDate.format(' — MMMM')
        }

        if (!thisYear) {
            result += endDate.format(', YYYY')
        }

        return result
    }

    const nextThreeDays = () => {
        setStartDate(moment(startDate).add(DISPLAY_DAYS, 'days'))
        setEndDate(moment(endDate).add(DISPLAY_DAYS, 'days'))
    }

    const prevThreeDays = () => {
        setStartDate(moment(startDate).subtract(DISPLAY_DAYS, 'days'))
        setEndDate(moment(endDate).subtract(DISPLAY_DAYS, 'days'))
    }

    const isDayAvailable = (day: Moment) => {
        if (
            (!minDate || day.isSameOrAfter(minDate, 'day')) &&
            availableSlots?.[day.format(API_DATE_FORMAT)]?.length > 0
        ) {
            return true
        }

        return false
    }

    const getAvailableTimeSlots = (): Models.ProcedureAvailability => {
        if (!procedure.availability || !procedure.availability[location.id]) {
            return []
        }
        return procedure.availability[location.id][selectedDate.format(API_DATE_FORMAT)]
    }

    const getIsSelectedTimeAvailable = (): boolean => {
        const slots = getAvailableTimeSlots()

        if (!selectedDate || !selectedDatetime || !slots) {
            return false
        }

        return Boolean(
            slots
                .map((time: string) => moment(`${selectedDate.format(API_DATE_FORMAT)}T${time}:00`))
                .find((datetime: Moment) => datetime.isSame(selectedDatetime)),
        )
    }

    const refresh = () => {
        setIsLoading(true)
        setTimeslotError(false)

        checkSchedule(selectedDate, true).finally(() => {
            if (!getIsSelectedTimeAvailable()) {
                dispatch(setAppointmentDatetime(chat, tab, undefined))
            }
            setIsLoading(false)
        })
    }

    const selectDay = (day: Moment) => () => {
        setTimeslotError(false)
        if (isDayAvailable(day) && !day.isSame(selectedDate, 'day')) {
            dispatch(selectAppointmentDay(chat, tab, day))
            checkSchedule(day, true)
        }
    }

    const selectTime = (datetime?: Moment) => () => {
        setTimeslotError(false)
        dispatch(setAppointmentDatetime(chat, tab, datetime))
    }

    const renderTimeSlotList = () => {
        if (!procedure.availability[location.id] || isLoading) {
            return (
                <div className={`${moduleName}__progress`}>
                    <CircularProgress size={50} />
                </div>
            )
        }

        const timeSlots = getAvailableTimeSlots()
        const isReceived = Array.isArray(timeSlots)
        const isEmpty = isReceived && timeSlots.length === 0

        return (
            <div className={`${moduleName}__appointments`}>
                {!isReceived && (
                    <div className={`${moduleName}__progress`}>
                        <CircularProgress size={50} />
                    </div>
                )}

                {isReceived && isEmpty && (
                    <SchedulingErrorMessage type="warning" message="No free time slots for booking" />
                )}

                {isReceived &&
                    !isEmpty &&
                    timeSlots
                        .map(time => {
                            return {
                                time,
                                datetime: moment.utc(time, 'YYYY-MM-DDTHH:mm'),
                            }
                        })
                        .map(({ time, datetime }) => (
                            <div
                                key={time}
                                className={`${moduleName}__time
                                ${Boolean(selectedDatetime && selectedDatetime.isSame(moment(time))) &&
                                    `${moduleName}__time--selected`}`}
                                onClick={selectTime(moment(time))}
                            >
                                {datetime.format('h:mm a')}
                            </div>
                        ))}
            </div>
        )
    }

    const days = new Array(endDate.diff(startDate, 'days') + 1)
        .fill(null)
        .map((day, index) => moment(startDate).add(index, 'days'))

    const timezoneAbbr =
        location.timezone && location.timezone.includes('/')
            ? moment()
                  .tz(location.timezone)
                  .zoneAbbr()
            : location.timezone

    const handleSelectDay = (date: Moment) => {
        const start = date.clone()
        const end = date.clone().add(DISPLAY_DAYS - 1, 'days')
        getAvailableDays(start, end)
        selectDay(date)()
        setStartDate(start)
        setEndDate(end)
        showCalendar(false)
    }

    const handleBackToCalendarView = () => {
        showCalendar(true)
    }

    const handleBookAppointment = async () => {
        if (!selectedDatetime) {
            return
        }

        showPreviewAppointmentOverlay(false)

        setIsLoading(true)
        setTimeslotError(false)

        getAvailableDays(startDate, endDate)
            .then((dateTimeSlots: string[]) => {
                if (dateTimeSlots.length === 0) {
                    if (isComponentMounted.current) setTimeslotError(true)
                } else {
                    if (dateTimeSlots.find(slot => selectedDatetime.isSame(moment(slot)))) {
                        try {
                            onBookAppointment()
                        } catch (err) {
                            dispatch(setAppointmentPickerError(chat, tab, err?.message || 'Undefined Error'))
                        }
                    } else {
                        if (isComponentMounted.current) setTimeslotError(true)
                    }
                }
            })
            .catch(() => {
                if (isComponentMounted.current) setTimeslotError(true)
            })
            .finally(() => {
                if (isComponentMounted.current) setIsLoading(false)
            })
    }

    const handlePreviewAppointment = () => {
        showPreviewAppointmentOverlay(true)
    }

    const handleMakeChanges = () => {
        showPreviewAppointmentOverlay(false)
    }

    return (
        <React.Fragment>
            {isCalendarShown && (
                <CalendarPicker
                    onConfirm={handleSelectDay}
                    getAvailableDays={getAvailableDays}
                    availableSlots={availableSlots}
                />
            )}

            {!isCalendarShown && (
                <div className={moduleName}>
                    <div className={`${moduleName}__refresh`} onClick={refresh}>
                        Refresh Schedule
                        <Icon>refresh</Icon>
                    </div>

                    <div className={`${moduleName}__header`}>
                        <div className={`${moduleName}__month`}>
                            {renderMonth()}
                            {location.timezone && <span className={`${moduleName}__timezone`}>({timezoneAbbr})</span>}
                        </div>
                        <div className={`${moduleName}__days-picker`}>
                            <div onClick={prevThreeDays} className={`${moduleName}__back`}>
                                <Icon>arrow_back_ios</Icon>
                            </div>
                            <div className={`${moduleName}__days`}>
                                {days.map(day => (
                                    <button
                                        disabled={!isDayAvailable(day)}
                                        key={day.format(API_DATE_FORMAT)}
                                        className={`${moduleName}__day
                                            ${day.isSame(selectedDate, 'days') ? `${moduleName}__day--selected` : ''}
                                            `}
                                        onClick={selectDay(day)}
                                    >
                                        <div className={`${moduleName}__date`}>{day.format('D')}</div>
                                        <div className={`${moduleName}__weekday`}>{day.format('ddd')}</div>
                                        {!availableSlots?.[day.format(API_DATE_FORMAT)] && (
                                            <div className={`${moduleName}__date-progress`}>
                                                <CircularProgress color="primary" />
                                            </div>
                                        )}
                                    </button>
                                ))}
                            </div>
                            <div className={`${moduleName}__forward`} onClick={nextThreeDays}>
                                <Icon>arrow_forward_ios</Icon>
                            </div>
                        </div>
                    </div>

                    {timeslotError && (
                        <SchedulingErrorMessage
                            type="warning"
                            message="The appointment you selected is no longer available. Please choose another appointment."
                        />
                    )}

                    {renderTimeSlotList()}

                    {!isLoading && (
                        <div
                            className={`${
                                DISPLAY_DAYS !== 3
                                    ? `${moduleName}__buttons-wrapper`
                                    : `${moduleName}__buttons-wrapper-narrow`
                            }`}
                        >
                            <div className={`${moduleName}__submit`}>
                                <Button className={`${moduleName}__back-button`} onClick={handleBackToCalendarView}>
                                    <i className={`material-icons ${moduleName}__back-icon`}>arrow_back</i>BACK
                                </Button>
                            </div>
                            <div className={`${moduleName}__submit`}>
                                <Button
                                    disabled={Boolean(!selectedDatetime || !isFormValid || timeslotError)}
                                    className={`${
                                        DISPLAY_DAYS !== 3
                                            ? `${moduleName}__submit-button`
                                            : `${moduleName}__submit-button-narrow`
                                    }`}
                                    onClick={handlePreviewAppointment}
                                >
                                    PREVIEW APPOINTMENT
                                </Button>
                            </div>
                        </div>
                    )}
                    {isPreviewAppointmentOverlayShown && (
                        <AppointmentPickerPreviewOverlay
                            patientName={patientName || ''}
                            selectedDate={selectedDate}
                            selectedDatetime={selectedDatetime}
                            location={location}
                            procedure={procedure}
                            onBookAppointment={handleBookAppointment}
                            isFormValid={isFormValid}
                            onMakeChanges={handleMakeChanges}
                            timeslotError={timeslotError}
                            displayDays={DISPLAY_DAYS}
                        />
                    )}
                </div>
            )}
        </React.Fragment>
    )
}

export default AppointmentPicker
