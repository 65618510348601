import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import sortBy from 'lodash/sortBy'

import { RootState } from '../../../appReducer'
import { fetchLocationsByWebcode } from '../../../modules/practices/v2actions'
import { receiveFormValueChange } from '../../../modules/referrals/referral-form/v2actions'
import CustomMultiselectField from '../../../modules/shared/custom-fields/CustomMultiselectField'
import { moveDefaultLocationToTop } from '../../../modules/shared/sortUtils'
import { useAppDispatch } from '../../../util/useAppDispatch'
import { ReferralFormSections } from '../shared/enums'

import './ReferralPracticeInfo.sass'

type Props = {
    isTestReferral: boolean
    practiceId?: string
    webCode?: ModelsV2.Referrals.WebCode
    chatter?: Api.Account
    isReferralPending?: boolean
    inboundHandlerAccounts: Api.Account[]
    isUpdatingReferral: boolean
}
const moduleName = 'referral-practice-info'

const ReferralPracticeInfo = (props: Props) => {
    const dispatch = useAppDispatch()

    const { isReferralPending, webCode, practiceId, isTestReferral, inboundHandlerAccounts, isUpdatingReferral } = props
    const webCodeId = webCode?.id
    const practiceLocations = useSelector(
        (state: RootState) => practiceId && state.v2.practices.practiceLocations[practiceId],
    )
    const practiceLocationsByWebCode = useSelector(
        (state: RootState) => webCodeId && state.v2.practices.practiceLocationsByWebCode[webCodeId],
    )

    const referral = useSelector((state: RootState) => state.v2.referrals.referralForm.referral.value)

    const [firstLoadFinished, setFirstLoadFinished] = useState(false)
    const [preselectedLocation, setPreselectedLocation] = useState(false)

    const referralId = referral?.id
    const isReferralLoaded = isUpdatingReferral ? referralId : true

    const onChange = (field: string, value: string) => {
        dispatch(receiveFormValueChange(ReferralFormSections.REFERRAL, field, value))
    }
    const listOfLocations = useCallback(() => {
        if (Boolean(isReferralPending) && webCode && practiceLocationsByWebCode) {
            return sortBy(
                practiceLocationsByWebCode
                    .filter(loc => loc.enabled)
                    .map(loc => {
                        return {
                            ...loc,
                            id: loc.practice_location_id,
                        }
                    }),
                loc => loc.name.toLowerCase(),
            )
        }
        if (practiceLocations) {
            return sortBy(practiceLocations, l => l.name.toLowerCase())
        }

        return []
    }, [isReferralPending, practiceLocations, practiceLocationsByWebCode, webCode])
    const locationList = listOfLocations().sort(moveDefaultLocationToTop)
    const listOfInboundHandlerAccounts = () => {
        return sortBy(inboundHandlerAccounts, h => h.first_name.toLowerCase()).map(handlers => {
            return {
                id: handlers.id,
                name: `${handlers.first_name} ${handlers.last_name}`,
            }
        })
    }
    const chatterList = listOfInboundHandlerAccounts()
    useEffect(() => {
        if (webCodeId) {
            dispatch(fetchLocationsByWebcode(webCodeId))
        }
    }, [dispatch, webCodeId])

    useEffect(() => {
        if (locationList.length > 0 && isTestReferral && !firstLoadFinished) {
            dispatch(receiveFormValueChange(ReferralFormSections.REFERRAL, 'location_id', locationList[0].id))
            setFirstLoadFinished(true)
        }
    }, [dispatch, firstLoadFinished, isTestReferral, locationList])

    useEffect(() => {
        if (isTestReferral && inboundHandlerAccounts.length > 0) {
            dispatch(
                receiveFormValueChange(
                    ReferralFormSections.REFERRAL,
                    'inbound_handler_id',
                    inboundHandlerAccounts[0].id,
                ),
            )
        }
    }, [dispatch, inboundHandlerAccounts, isTestReferral])

    useEffect(() => {
        if (locationList.length === 1 && isReferralLoaded && !preselectedLocation) {
            dispatch(receiveFormValueChange(ReferralFormSections.REFERRAL, 'location_id', locationList[0].id))
            setPreselectedLocation(true)
        }
    }, [preselectedLocation, locationList, isReferralLoaded, dispatch])

    const inboundHandlerId = referral?.inbound_handler_id
    const referralLocationId = referral?.location_id

    return (
        <Grid container={true} spacing={0} className={`${moduleName}`}>
            <Grid item={true} xs={12} sm={6} className={`${moduleName}__location-container`}>
                <CustomMultiselectField
                    items={locationList}
                    isLoading={!Boolean(locationList.length)}
                    maxSelected={1}
                    selectedItems={[referralLocationId || '']}
                    search={Boolean(locationList.length) && locationList.length > 8}
                    keyProperty={'id'}
                    label="Select Practice Location*"
                    disableDropDown={Boolean(referralLocationId && locationList.length === 1)}
                    displayProperty={'name'}
                    searchPlaceholder="Search Location"
                    placeholder={'Select Location'}
                    onSelectElement={values => {
                        const value = values[0]
                        onChange('location_id', value)
                    }}
                ></CustomMultiselectField>
            </Grid>
            <Grid item={true} xs={12} sm={6} className={`${moduleName}__chatter-container`}>
                <CustomMultiselectField
                    items={chatterList}
                    maxSelected={1}
                    selectedItems={[inboundHandlerId || '']}
                    search={Boolean(chatterList.length) && chatterList.length > 8}
                    keyProperty={'id'}
                    label="Chatter*"
                    displayProperty={'name'}
                    placeholder={'Select Chatter'}
                    searchPlaceholder="Search Chatter"
                    isLoading={!Boolean(chatterList.length)}
                    onSelectElement={values => {
                        const value = values[0]
                        onChange('inbound_handler_id', value)
                    }}
                ></CustomMultiselectField>
            </Grid>
        </Grid>
    )
}

export default ReferralPracticeInfo
