import * as React from 'react'
import ReactModal from 'react-modal'
import CircularProgress from '@mui/material/CircularProgress'
import Fab from '@mui/material/Fab'
import classNames from 'classnames'
import _ from 'lodash'

import AdminAccount from '../../models/AdminAccount'
import { SelectedPractice } from '../../models/enums'
import { SearchBar } from '../shared/custom-fields/SearchBar'
import Paginator from '../shared/Paginator'

import { PracticeLocationsState } from './locations/reducer'
import { resetSearchPracticePatients } from './patients/v2actions'
import {
    createLocation,
    createPractice,
    fetchAvailablePracticeUserRoles,
    fetchPracticeList,
    fetchPracticeLocations,
    fetchPracticeLocationsPaginated,
    getZipCodeTimezone,
    PracticeCreateSteps,
    savePractice,
    saveSelectedPractice,
    setCreateStep,
    setPracticeSearchTerms,
} from './actions'
import CreatePracticeForm from './CreatePracticeForm'
import CreatePracticeSearch from './CreatePracticeSearch'
import PracticeQuickview from './PracticeQuickview'

import './PracticeList.sass'
import { RouteComponentProps, withRouter } from 'react-router-dom'

const customReactModalStyles = {
    overlay: { zIndex: 1000 },
}

export type PracticeListProps = {
    account?: AdminAccount
    featureFlags?: ApiV2.FeatureFlags.FeatureFlags
    practices: { [key: string]: Models.Practice }
    practiceList: string[]
    createState: {
        step: PracticeCreateSteps
        name?: string
    }
    paginationInfo?: Models.PaginationInfo
    selectedPractice?: SelectedPractice
    searchTerms: Models.SearchTerms
    practiceLocationTabData: PracticeLocationsState
} & RouteComponentProps

export type PracticeListDispatch = {
    fetchPracticeList: typeof fetchPracticeList
    createPractice: typeof createPractice
    createLocation: typeof createLocation
    resetSearchPracticePatients: typeof resetSearchPracticePatients
    setCreateStep: typeof setCreateStep
    savePractice: typeof savePractice
    saveSelectedPractice: typeof saveSelectedPractice
    setPracticeSearchTerms: typeof setPracticeSearchTerms
    fetchAvailablePracticeUserRoles: typeof fetchAvailablePracticeUserRoles
    getZipCodeTimezone: typeof getZipCodeTimezone
    fetchPracticeLocations: typeof fetchPracticeLocations
    fetchPracticeLocationsPaginated: typeof fetchPracticeLocationsPaginated
}

type Props = PracticeListProps & PracticeListDispatch

class PracticeList extends React.Component<Props> {
    throttledFetchPractices: (searchTerms: Api.Amplify.PracticesSearchTerms) => void

    constructor(props: Props) {
        super(props)

        this.throttledFetchPractices = _.throttle(this.fetchPractices, 750)
    }

    async UNSAFE_componentWillMount() {
        const selectedPracticeId = this.props.selectedPractice?.id
        await this.props.fetchPracticeList(this.props.searchTerms)
        await this.props.fetchAvailablePracticeUserRoles()

        if (selectedPracticeId && this.props.practices[selectedPracticeId]) {
            const selectedPractice = this.props.selectedPractice
            const practiceLocationTabMetadata = this.props.practiceLocationTabData[selectedPracticeId]?.metadata

            if (selectedPractice?.selectedTab === 'locations') {
                await this.props.fetchPracticeLocationsPaginated(this.props.practices[selectedPracticeId], {
                    search: practiceLocationTabMetadata?.search || '',
                    page: practiceLocationTabMetadata?.page || 1,
                })
            } else {
                await this.props.fetchPracticeLocations(selectedPracticeId)
            }
        }
    }

    onCloseCreatePractice = () => {
        this.props.setCreateStep(null)
    }
    onContinueCreate(name: string) {
        this.props.setCreateStep('register', name)
    }
    onShowCreatePractice = () => {
        this.props.setCreateStep('start')
    }
    onCreatePractice(practice: Api.CreatePractice, locations?: ApiV2.Practice.CreatePracticeLocation[]) {
        this.props.createPractice(practice).then(async (practiceId: string) => {
            for (const location of locations || []) {
                await this.props.createLocation(practiceId, location)
            }
            this.onCloseCreatePractice()
        })
    }
    componentDidMount() {
        const { location, searchTerms } = this.props
        const searchParams = new URLSearchParams(location.search)

        const searchKey = searchParams.get('search')
        const page = parseInt(searchParams.get('page') || '1', 10)

        if (searchKey && searchKey !== searchTerms.searchKey) {
            this.searchByKeyword({ searchKey, page })
        }
    }
    selectPage = async (page: number) => {
        this.updateQueryParams(page, this.props.searchTerms.searchKey)
        await this.props.setPracticeSearchTerms({ ...this.props.searchTerms, page })
        this.fetchPractices({ ...this.props.searchTerms, page })
    }
    onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchKey = e.target && encodeURIComponent(e.target.value)
        const searchTerms = { searchKey, page: 1 }
        this.searchByKeyword(searchTerms)
    }
    clearSearch = () => {
        const searchKey = ''
        const searchTerms = { searchKey, page: 1 }

        this.removeQueryParams()
        this.searchByKeyword(searchTerms)
    }
    updateQueryParams = (page?: number, searchKey?: string) => {
        const { history, location } = this.props
        const searchParams = new URLSearchParams(location.search)

        if (searchKey) {
            searchParams.set('search', searchKey)
            searchParams.set('page', String(page))
        } else {
            searchParams.delete('search')
            searchParams.delete('page')
        }

        if (searchParams.toString() !== location.search) {
            history.push({
                pathname: location.pathname,
                search: searchParams.toString(),
            })
        }
    }
    removeQueryParams = () => {
        const { history, location } = this.props
        history.push({
            pathname: location.pathname,
            search: '',
        })
    }
    searchByKeyword = async (searchTerms: Models.SearchTerms) => {
        this.updateQueryParams(searchTerms.page, searchTerms.searchKey)

        await this.props.setPracticeSearchTerms(searchTerms)
        this.throttledFetchPractices(searchTerms)
    }
    fetchPractices = async (searchTerms: Api.Amplify.PracticesSearchTerms) => {
        await this.props.fetchPracticeList(searchTerms)
        await this.props.saveSelectedPractice({ id: '', selectedTab: undefined })
    }

    modalStep() {
        switch (this.props.createState.step) {
            case 'start':
                return (
                    <CreatePracticeSearch
                        onSave={(name: string) => this.onContinueCreate(name)}
                        onClose={this.onCloseCreatePractice}
                        searchByKeyword={this.searchByKeyword}
                    />
                )
            case 'register':
                return (
                    <CreatePracticeForm
                        name={this.props.createState.name || ''}
                        onSave={(practice: Api.CreatePractice, locations?: ApiV2.Practice.CreatePracticeLocation[]) =>
                            this.onCreatePractice(practice, locations)
                        }
                        onClose={this.onCloseCreatePractice}
                        getZipCodeTimezone={this.props.getZipCodeTimezone}
                    />
                )
            case null:
                return <div />
            default:
                return <div />
        }
    }

    render() {
        const {
            account,
            featureFlags,
            practices,
            practiceList,
            paginationInfo,
            selectedPractice,
            searchTerms: { page = 1, searchKey = '' } = {},
        } = this.props

        const isCreatePracticeEnabled = featureFlags?.enablePstPracticeCreation !== false

        if (!account) {
            return (
                <div className="practice-list-circular-progress-loader">
                    <CircularProgress size={100} color="primary" variant="indeterminate" />
                </div>
            )
        }

        return (
            <div className={classNames('practice-list')}>
                <div className="create-button">
                    {account.isSimplifeyeStaff && isCreatePracticeEnabled && (
                        <Fab
                            className="practice-create-button"
                            onClick={this.onShowCreatePractice}
                            title="Create new practice"
                            color="primary"
                        >
                            <i className="material-icons icon">add</i>
                        </Fab>
                    )}
                </div>

                <ReactModal
                    className="practice-list-create-modal"
                    contentLabel="Create Practice"
                    isOpen={this.props.createState.step != null}
                    onRequestClose={this.onCloseCreatePractice}
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEsc={false}
                    ariaHideApp={false}
                    style={customReactModalStyles}
                >
                    <i className="close-modal material-icons" onClick={this.onCloseCreatePractice}>
                        close
                    </i>
                    {this.modalStep()}
                </ReactModal>

                <SearchBar
                    value={searchKey}
                    onChange={this.onSearch}
                    onClear={this.clearSearch}
                    isMessageShown={searchKey.length > 0 && !practiceList.length}
                    placeholder="Search by Practice Name or Practice ID"
                />

                <div className="list">
                    {practiceList.map(id => {
                        const practice = practices[id]
                        return (
                            <PracticeQuickview
                                key={practice.id}
                                practice={practice}
                                account={account}
                                savePractice={this.props.savePractice}
                                canEdit={account.isSimplifeyeStaff}
                                saveSelectedPractice={this.props.saveSelectedPractice}
                                resetSearchPracticePatients={this.props.resetSearchPracticePatients}
                                selectedTab={
                                    selectedPractice && id === selectedPractice.id
                                        ? selectedPractice.selectedTab
                                        : undefined
                                }
                                practiceSearchTerms={this.props.searchTerms}
                            />
                        )
                    })}
                </div>
                <div className="paginator-wrapper">
                    <Paginator currentPage={page} paginationInfo={paginationInfo} selectPage={this.selectPage} />
                </div>
            </div>
        )
    }
}

export default withRouter(PracticeList)
