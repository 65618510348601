import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Icon from '@mui/material/Icon'
import { moveDefaultLocationToTop } from 'modules/shared/sortUtils'

import { RootState } from '../../../../appReducer'
import { HeartbeatAgentLocation } from '../../../../models/Integrations'
import { useAppDispatch } from '../../../../util/useAppDispatch'
import {
    fetchNumberOfEnabledSelfSchedulingWebCodeLocations,
    saveSelfSchedulingWebCodeLocation,
    searchSelfSchedulingWebCodesLocations,
    setAllRSVPsEnabled,
    toggleEnableAllSelfSchedulingWebCodeLocations,
} from '../actions'

import SelfSchedulingWebCodeLocation from './SelfSchedulingWebCodeLocation'

import './SelfSchedulingWebCodeLocationsList.sass'
import SelfSchedulingWebCodeSelectAllLocations from './SelfSchedulingWebCodeSelectAllLocations'

const moduleName = 'self-scheduling-webcode-locations-list'

export type Props = {
    webCode: Models.SelfSchedulingWebCode
    practice: Models.Practice
    modalRef: React.RefObject<any>
}

function SelfSchedulingWebCodeLocationsList(props: Props) {
    const { webCode, practice } = props
    const practiceId = practice.id
    const webCodeId = webCode.id

    const webCodeLocations = useSelector((state: RootState) =>
        webCodeId ? state.selfScheduling.webCodesLocations[webCodeId] : undefined,
    )

    const numberOfEnabledLocations = useSelector(
        (state: RootState) => state.selfScheduling.numberOfEnabledLocations[webCodeId],
    )
    const webCodeLocationsData = useMemo(() => webCodeLocations?.locations.sort(moveDefaultLocationToTop) || [], [
        webCodeLocations?.locations,
    ])
    const allLocationsSelectedFromProps = Boolean(webCodeLocationsData[0]?.allLocationsEnabled)
    const rsvpEnabledLocations = useSelector(
        (state: RootState) => state.selfScheduling.rsvpEnabledLocations[props.practice.id],
    )
    const dispatch = useAppDispatch()

    const [currentScrollPosition, setCurrentScrollPositions] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [search, setSearch] = useState<boolean>(false)
    const [searchKeyword, setSearchKeyword] = useState<string>('')
    const [allLocationsSelected, setAllLocationsSelected] = useState<boolean>(allLocationsSelectedFromProps)

    const [currentPage, setCurrentPage] = useState<number>(1)
    const [allPages, setAllPages] = useState<number>(1)

    const currentPageRef = React.useRef(currentPage)
    const allPagesRef = React.useRef(allPages)
    const listRef = React.useRef<HTMLDivElement>()

    const hasRSVPProduct = Boolean(
        props.practice.products.find(product => product.value === 'chairfill' && product.active === true),
    )

    const setCurrentPageState = (data: number) => {
        currentPageRef.current = data
        setCurrentPage(data)
    }

    const setAllPagesState = (data: number) => {
        allPagesRef.current = data
        setAllPages(data)
    }

    const infiniteScrollDiv = useRef<HTMLDivElement>()

    const getLocationRSVPAvailability = useCallback(
        (location: HeartbeatAgentLocation) => {
            const connectedWebCodeId = getConnectedWebCodeId(location)
            const isLocationAlreadyConnected = Boolean(connectedWebCodeId)
            const isLocationConnectedWithThisWebcode = isLocationAlreadyConnected && connectedWebCodeId === webCode.id
            const isRSVPAvailable = !isLocationAlreadyConnected || isLocationConnectedWithThisWebcode

            return {
                isRSVPAvailable,
                isRSVPEnabled: isLocationConnectedWithThisWebcode,
                isLocationAlreadyConnected,
                connectedWebCodeId,
            }
        },
        [webCode.id],
    )

    useEffect(() => {
        dispatch(fetchNumberOfEnabledSelfSchedulingWebCodeLocations(webCode.id))
    }, [practice.id, dispatch])

    useEffect(() => {
        const searchTerms = {
            searchKey: searchKeyword,
            page: 1,
        }

        dispatch(searchSelfSchedulingWebCodesLocations(practiceId, webCodeId, searchTerms))
    }, [practiceId, webCodeId, searchKeyword, dispatch])

    useEffect(() => {
        const infiniteScrollDivCurrent = infiniteScrollDiv.current

        const loadMore = async () => {
            if (currentPageRef.current && currentPageRef.current < allPagesRef.current) {
                setLoading(true)
                const searchTerms = {
                    searchKey: searchKeyword,
                    page: currentPageRef.current + 1,
                }
                await dispatch(searchSelfSchedulingWebCodesLocations(practiceId, webCodeId, searchTerms))
                setLoading(false)
            }
        }

        const onScroll = async (): Promise<any> => {
            if (infiniteScrollDivCurrent) {
                let th = infiniteScrollDivCurrent.scrollTop + infiniteScrollDivCurrent.clientHeight
                let c = infiniteScrollDivCurrent.scrollHeight

                if (c - th <= 1) {
                    if (infiniteScrollDivCurrent.scrollTop !== 0) {
                        setCurrentScrollPositions(infiniteScrollDivCurrent.scrollTop)
                        await loadMore()
                    }
                }
            }
        }

        if (infiniteScrollDivCurrent) {
            infiniteScrollDivCurrent.addEventListener('scroll', onScroll)
            infiniteScrollDivCurrent.scrollTop = currentScrollPosition
        }

        return () => {
            if (infiniteScrollDivCurrent) {
                infiniteScrollDivCurrent.removeEventListener('scroll', onScroll, false)
            }
        }
    }, [practiceId, webCodeId, searchKeyword, currentScrollPosition, dispatch])

    useEffect(() => {
        setCurrentPageState(webCodeLocations?.currentPage || 1)
        setAllPagesState(webCodeLocations?.paginationInfo?.allPages || 1)
        if (webCodeLocations?.paginationInfo?.allRows && webCodeLocations.paginationInfo.allRows > 10) {
            setSearch(true)
        }
    }, [webCodeLocations])

    useEffect(() => {
        if (rsvpEnabledLocations?.length > 0 && webCodeLocationsData.length > 0) {
            let hasAtLeastOneEnabledRSVP = false
            const hasAvailableRSVP = rsvpEnabledLocations.some(enabledLocation => {
                const { isRSVPAvailable, isRSVPEnabled, isLocationAlreadyConnected } = getLocationRSVPAvailability(
                    enabledLocation,
                )
                if (isRSVPEnabled) {
                    hasAtLeastOneEnabledRSVP = true
                }
                if (!isRSVPAvailable || isRSVPEnabled || isLocationAlreadyConnected) {
                    return false
                }

                const isWebCodeLocationEnabled = Boolean(
                    webCodeLocationsData.find(loc => loc.practiceLocationId === enabledLocation.practiceLocation.id)
                        ?.enabled,
                )
                return isWebCodeLocationEnabled
            })

            dispatch(setAllRSVPsEnabled(webCode.id, hasAtLeastOneEnabledRSVP ? !hasAvailableRSVP : false))
        }
    }, [rsvpEnabledLocations, webCodeLocationsData, getLocationRSVPAvailability, webCode.id, dispatch])

    useEffect(() => {
        setAllLocationsSelected(allLocationsSelectedFromProps)
    }, [allLocationsSelectedFromProps])

    const updateLocation = async (locationId: string, marked: boolean) => {
        const { webCode, practice } = props

        await dispatch(
            saveSelfSchedulingWebCodeLocation({
                practiceId: practice.id,
                webCodeId: webCode.id,
                locationId: locationId,
                enabled: marked,
            }),
        )

        if (hasRSVPProduct && marked) {
            const location = rsvpEnabledLocations?.filter(loc => loc.practiceLocation.id === locationId)[0]
            const { isRSVPAvailable, isRSVPEnabled } = getLocationRSVPAvailability(location)

            if (isRSVPAvailable && !isRSVPEnabled) {
                dispatch(setAllRSVPsEnabled(webCode.id, false))
            }
        }

        const searchTerms = {
            searchKey: searchKeyword,
            page: 1,
        }

        dispatch(fetchNumberOfEnabledSelfSchedulingWebCodeLocations(webCode.id))
        dispatch(searchSelfSchedulingWebCodesLocations(practiceId, webCodeId, searchTerms))

        setAllLocationsSelected(false)
    }

    const selectAllLocations = async (checked: boolean) => {
        const { webCode, practice } = props
        const searchTerms = {
            limit: 50,
            page: 1,
            searchKey: searchKeyword,
        }

        await dispatch(
            toggleEnableAllSelfSchedulingWebCodeLocations(
                {
                    practiceId: practice.id,
                    webCodeId: webCode.id,
                    enabled: checked,
                },
                searchTerms,
            ),
        )
        dispatch(fetchNumberOfEnabledSelfSchedulingWebCodeLocations(webCode.id))
        setAllLocationsSelected(checked)
    }

    const getConnectedWebCodeId = (enabledLocation: HeartbeatAgentLocation) => {
        if (enabledLocation?.chairfillSelfSchedulingWebCode?.id) {
            return enabledLocation?.chairfillSelfSchedulingWebCode?.id
        }
        return ''
    }

    const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => setSearchKeyword(event.target.value)

    const handleSearchClear = () => setSearchKeyword('')

    return (
        <div className={moduleName} ref={listRef as React.RefObject<any>}>
            {search && (
                <div className={`${moduleName}__search`}>
                    <Icon className={`${moduleName}__search-icon`}>search</Icon>
                    <input
                        className={`${moduleName}__search-field`}
                        placeholder="Search Location"
                        value={searchKeyword}
                        onChange={handleChangeSearch}
                    />
                    {searchKeyword && (
                        <span className={`${moduleName}__search-clear`} onClick={handleSearchClear}>
                            <Icon className={`${moduleName}__search-icon`}>clear</Icon>
                        </span>
                    )}
                </div>
            )}
            {!searchKeyword && (
                <SelfSchedulingWebCodeSelectAllLocations
                    allLocationsSelected={allLocationsSelected}
                    selectAllLocations={selectAllLocations}
                />
            )}
            <div className={`${moduleName}__locations-container`} ref={infiniteScrollDiv as React.RefObject<any>}>
                {webCodeLocations &&
                    webCodeLocationsData.length > 0 &&
                    webCodeLocationsData.map(location => (
                        <SelfSchedulingWebCodeLocation
                            key={location.practiceLocationId}
                            location={location}
                            updateLocation={updateLocation}
                        />
                    ))}
                {webCodeLocations && webCodeLocations.locations.length === 0 && (
                    <div className={`${moduleName}__not-found`}>
                        No practices found with the search “{searchKeyword}”
                    </div>
                )}
                {!webCodeLocations && <div>Loading...{loading}</div>}
                <p className={`${moduleName}__error`}>
                    {numberOfEnabledLocations === 0 && 'Please select location(s)'}
                </p>
            </div>
        </div>
    )
}

export default SelfSchedulingWebCodeLocationsList
