import Api from '../../Api'
import { mapFullPracticeLocations, mapFullPracticeLocationsWithProducts } from '../../Api/mappers'
import ApiV2 from '../../ApiV2'
import { RootState } from '../../appReducer'
import { AppDispatch, AppThunk } from '../../appStore'
import { Products, SelectedPractice } from '../../models/enums'

import { setLocationListMeta } from './locations/actions'
import { defaultTimezone } from './locations/constants'
import permissionListData from './staff/permissionListData'
import { receiveUserPhoneNumberBlackList as receiveUserPhoneNumberBlackListV2 } from './v2actions'

export type PracticeCreateSteps = 'start' | 'register' | null

export type CreatePractice = {
    type: 'CREATE_PRACTICE'
    practice: Api.Practice
}

export type SavePractice = {
    type: 'SAVE_PRACTICE'
    practice: Api.Practice
}

export type ReceivePractice = {
    type: 'RECEIVE_PRACTICE'
    practice: Api.Practice
}

export type DeletePracticeLocation = {
    type: 'DELETE_PRACTICE_LOCATION'
    practice: Models.Practice
    location: Api.PracticeLocation
}

export type ReceivePracticeLocation = {
    type: 'RECEIVE_PRACTICE_LOCATION'
    practice: Models.Practice
    location: Api.PracticeLocation
}

export type ReceivePracticeWithLocations = {
    type: 'RECEIVE_PRACTICE_WITH_LOCATIONS'
    practice: Api.Practice
    locations: Api.PracticeLocation[]
}

export type ReceiveCreatedPracticeLocation = {
    type: 'RECEIVE_CREATED_PRACTICE_LOCATION'
    practice: Models.Practice
    location: ModelsV2.Practice.CreatePracticeLocationResponse
}

export type ReceivePracticeLocations = {
    type: 'RECEIVE_PRACTICE_LOCATIONS'
    practiceId: string
    locations: Array<Api.PracticeLocation>
}

export type ReceivePracticeLocationsWithOwnProducts = {
    type: 'RECEIVE_PRACTICE_LOCATIONS_WITH_OWN_PRODUCTS'
    practiceId: string
    locations: Array<Api.PracticeLocation>
}

export type ReceiveLocationVyneProducts = {
    type: 'RECEIVE_LOCATION_VYNE_PRODUCTS'
    practiceId: string
    locationId: string
    products: string[]
}

export type SetLocationSurveyStatusToCompleted = {
    type: 'SET_LOCATION_SURVEY_STATUS_TO_COMPLETED'
    practiceId: string
    locationId: string
}

export type ReceivePractices = {
    type: 'RECEIVE_PRACTICES'
    practices: Api.Practice[]
    paginationInfo?: Api.PaginationInfo
}

export type SetSelectedPractice = {
    type: 'SET_SELECTED_PRACTICE'
    selectedPractice: SelectedPractice
}

export type ReceiveAccount = {
    type: 'RECEIVE_ACCOUNT'
    practice: Models.Practice
    account: Api.Account
}

export type ReceiveAccountStatus = {
    type: 'RECEIVE_ACCOUNT_STATUS'
    practice: Models.Practice
    account: Models.Account
    active: boolean
}

export type ReceivePermissionList = {
    type: 'RECEIVE_PERMISSION_LIST'
    permissions: Array<Api.Permission>
}

export type ReceivePracticeProducts = {
    type: 'RECEIVE_PRACTICE_PRODUCTS'
    practiceId: string
    products: Array<Api.Product>
}

export type ReceivePracticeLocationProducts = {
    type: 'RECEIVE_PRACTICE_LOCATION_PRODUCTS'
    practiceId: string
    locationId: string
    products: any
}

export type SavingPracticeProducts = {
    type: 'SAVING_PRACTICE_PRODUCTS'
    isSaving: boolean
}

export type SavingPracticeLocationProducts = {
    type: 'SAVING_PRACTICE_LOCATION_PRODUCTS'
    isSaving: boolean
}

export type SetCreateStep = {
    type: 'SET_PRACTICE_CREATE_STEP'
    step: PracticeCreateSteps
    name?: string
}

export type ReceivePracticeAgents = {
    type: 'RECEIVE_PRACTICE_AGENTS'
    practice: Models.Practice
    agents: Array<Api.PracticeAgent>
}

export type ReceivePracticeAgent = {
    type: 'RECEIVE_PRACTICE_AGENT'
    practice: Models.Practice
    agent: Api.PracticeAgent
}

export type ReceiveUnboundPracticeAgent = {
    type: 'RECEIVE_UNBOUND_PRACTICE_AGENT'
    practice: Models.Practice
    agent: Api.PracticeAgent
}

export type UpdatePracticeSearchTerms = {
    type: 'UPDATE_PRACTICE_SEARCH_TERMS'
    searchTerms: Models.SearchTerms
}

export type ReceivePracticeUserRoles = {
    type: 'RECEIVE_AVAILABLE_PRACTICE_USER_ROLES'
    roles: Models.PracticeUserRole[]
}

export type ReceivePracticeLogo = {
    type: 'RECEIVE_PRACTICE_LOGO'
    practice: Models.Practice
    logo?: Models.PracticeLogo
}

export type ReceivePracticeLocationProcedures = {
    type: 'RECEIVE_PRACTICE_LOCATION_PROCEDURES'
    locationId: string
    practice: Models.Practice
    procedures: Models.Procedure[]
}

export type ReceivePracticeLocationProcedureProviders = {
    type: 'RECEIVE_PRACTICE_LOCATION_PROCEDURE_PROVIDERS'
    locationId: string
    practice: Models.Practice
    procedure: Models.Procedure
    providers: Models.Provider[]
}

export type ReceiveProcedureAvailability = {
    type: 'RECEIVE_PROCEDURE_AVAILABILITY'
    location: Models.PracticeLocation
    procedure: Models.Procedure
    date: string
    availability: Models.ProcedureAvailability
}

export type ReceiveAvailability = {
    type: 'RECEIVE_AVAILABILITY'
    location: Models.PracticeLocation
    procedure: Models.Procedure
    startDate: string
    endDate: string
    availability: Models.ProcedureAvailability
}

export type ReceivePracticeHasEmailNotificationSubscribers = {
    type: 'RECEIVE_PRACTICE_HAS_EMAIL_NOTIFICATION_SUBSCRIBERS'
    practice: Models.Practice
    hasEmailNotificationSubscribers: Boolean
}

export type ReceiveLocationSchedulingData = {
    type: 'RECEIVE_LOCATION_SCHEDULING_DATA'
    practice: Models.Practice
    location: Models.PracticeLocation
    schedulingData?: Api.SchedulingPracticeLocationJarvisInfo | Api.LocationScheduling
}

export type ReceiveLocationConnectPaymentInfoData = {
    type: 'RECEIVE_LOCATION_CONNECT_PAYMENT_INFO_DATA'
    practiceId: string
    locationId: string
    connectPaymentInfoData: Models.LocationConnectPaymentInfo | null
}

export type ReceiveLocationsSchedulingData = {
    type: 'RECEIVE_LOCATIONS_SCHEDULING_DATA'
    practiceId: string
    schedulingData: ModelsV2.Practice.PracticeSchedulingLocationsData[]
}

export type ReceivePracticeLocationsAgentModules = {
    type: 'RECEIVE_PRACTICE_LOCATIONS_AGENT_MODULES'
    practiceId: string
    schedulingData: ModelsV2.Practice.PracticeSchedulingLocationsData[]
}

export type ReceiveLocationPaymentsInfo = {
    type: 'RECEIVE_LOCATION_PAYMENTS_INFO'
    practiceId: string
    locationId: string
    paymentsInfo: Api.LocationPaymentsInfo
}

export type ReceiveLocationStripeAccountId = {
    type: 'RECEIVE_LOCATION_STRIPE_ACCOUNT_ID'
    practiceId: string
    locationId: string
    stripeAccountId?: string
    stripeLocationId?: string
}

export type ReceiveLocationStatementDescriptor = {
    type: 'RECEIVE_LOCATION_STATEMENT_DESCRIPTOR'
    practiceId: string
    locationId: string
    statementDescriptor: string
}

export type UpdateAllLocationsStripeAccountId = {
    type: 'UPDATE_ALL_LOCATIONS_STRIPE_ACCOUNT_ID'
    practiceId: string
    stripeAccountId: string
}

export type UpdateSingleLocationStripeAccountId = {
    type: 'UPDATE_SINGLE_LOCATION_STRIPE_ACCOUNT_ID'
    practiceId: string
    locationId: string
    stripeAccountId: string
}

export type RemoveLocationPaymentsTerminal = {
    type: 'REMOVE_LOCATION_PAYMENTS_TERMINAL'
    terminalId: string
    practiceId: string
    locationId: string
}

export type AddLocationPaymentsTerminal = {
    type: 'ADD_LOCATION_PAYMENTS_TERMINAL'
    practiceId: string
    locationId: string
    terminalData: Models.LocationPaymentsTerminalInfo
}

export type ReceiveUserPhoneNumberBlackList = {
    type: 'RECEIVE_USER_PHONE_NUMBER_BLACK_LIST'
    practiceId: string
    accountId: string
    phoneNumberBlackList: string[]
}

export type ReceivePracticeLogoBgColor = {
    type: 'RECEIVE_PRACTICE_LOGO_BG_COLOR'
    practiceId: string
    bgColor: Models.LogoBgColorOptions
}

export type UpdateLocationCompletionStatus = {
    type: 'UPDATE_LOCATION_COMPLETION_STATUS'
    practiceId: string
    locationId: string
    allFieldsCompleted: boolean
    completionStatus: {
        stripeID: boolean
        paymentsRates: boolean
    }
}

export type ReceiveLocationMilestone = {
    type: 'RECEIVE_LOCATION_MILESTONE'
    practiceId: string
    locationId: string
    isCompleted: boolean
}

export type UpdatePaymentRatesStatus = {
    type: 'UPDATE_PAYMENT_RATES_STATUS'
    practiceId: string
    locationId: string
    status: boolean
}

export type SetIsSavingPracticeStaff = {
    type: 'SET_IS_SAVING_PRACTICE_STAFF'
    practiceId: string
    isSavingPracticeStaff: boolean
}

export type FetchingAllPracticeLocations = {
    type: 'FETCHING_ALL_PRACTICE_LOCATIONS'
    practiceId: string
    fetching: boolean
}

const receivePracticeLogoBgColor = (
    practiceId: string,
    bgColor: Models.LogoBgColorOptions,
): ReceivePracticeLogoBgColor => {
    return {
        type: 'RECEIVE_PRACTICE_LOGO_BG_COLOR',
        practiceId,
        bgColor,
    }
}

export function createdPractice(practice: Api.Practice): CreatePractice {
    return {
        type: 'CREATE_PRACTICE',
        practice,
    }
}

export function savedPractice(practice: Api.Practice): SavePractice {
    return {
        type: 'SAVE_PRACTICE',
        practice,
    }
}

export function receivePractice(practice: Api.Practice): ReceivePractice {
    return {
        type: 'RECEIVE_PRACTICE',
        practice,
    }
}

export function receiveAccount(practice: Models.Practice, account: Api.Account): ReceiveAccount {
    return {
        type: 'RECEIVE_ACCOUNT',
        practice,
        account,
    }
}

export function receiveAccountStatus(
    practice: Models.Practice,
    account: Models.Account,
    active: boolean,
): ReceiveAccountStatus {
    return {
        type: 'RECEIVE_ACCOUNT_STATUS',
        practice,
        account,
        active,
    }
}

export function receivePracticeLocation(
    practice: Models.Practice,
    location: Api.PracticeLocation,
): ReceivePracticeLocation {
    return {
        type: 'RECEIVE_PRACTICE_LOCATION',
        practice,
        location,
    }
}

export function receivePracticeWithLocations(
    practice: Api.Practice,
    locations: Api.PracticeLocation[],
): ReceivePracticeWithLocations {
    return {
        type: 'RECEIVE_PRACTICE_WITH_LOCATIONS',
        practice,
        locations,
    }
}

export function receiveCreatedPracticeLocation(
    practice: Models.Practice,
    location: ModelsV2.Practice.CreatePracticeLocationResponse,
): ReceiveCreatedPracticeLocation {
    return {
        type: 'RECEIVE_CREATED_PRACTICE_LOCATION',
        practice,
        location,
    }
}

export function receivePracticeLocations(
    practiceId: string,
    locations: Array<Api.PracticeLocation>,
): ReceivePracticeLocations {
    return {
        type: 'RECEIVE_PRACTICE_LOCATIONS',
        practiceId,
        locations,
    }
}

export function receivePracticeLocationsWithOwnProducts(
    practiceId: string,
    locations: Array<Api.PracticeLocation>,
): ReceivePracticeLocationsWithOwnProducts {
    return {
        type: 'RECEIVE_PRACTICE_LOCATIONS_WITH_OWN_PRODUCTS',
        practiceId,
        locations,
    }
}

export function receiveLocationVyneProducts(
    practiceId: string,
    locationId: string,
    products: string[],
): ReceiveLocationVyneProducts {
    return {
        type: 'RECEIVE_LOCATION_VYNE_PRODUCTS',
        practiceId,
        locationId,
        products,
    }
}

export function setLocationSurveyStatusToCompleted(
    practiceId: string,
    locationId: string,
): SetLocationSurveyStatusToCompleted {
    return {
        type: 'SET_LOCATION_SURVEY_STATUS_TO_COMPLETED',
        practiceId,
        locationId,
    }
}

export function receivePracticeList(practices: Api.Practice[], paginationInfo?: Api.PaginationInfo): ReceivePractices {
    return {
        type: 'RECEIVE_PRACTICES',
        practices,
        paginationInfo,
    }
}

export function setSelectedPractice(selectedPractice: SelectedPractice): SetSelectedPractice {
    return {
        type: 'SET_SELECTED_PRACTICE',
        selectedPractice,
    }
}

export function receivePermissionList(permissions: Array<Api.Permission>): ReceivePermissionList {
    return {
        type: 'RECEIVE_PERMISSION_LIST',
        permissions,
    }
}

export function receivePracticeProducts(practiceId: string, products: Array<Api.Product>): ReceivePracticeProducts {
    return {
        type: 'RECEIVE_PRACTICE_PRODUCTS',
        practiceId,
        products,
    }
}

export function receivePracticeLocationProducts(
    practiceId: string,
    locationId: string,
    products: any,
): ReceivePracticeLocationProducts {
    return {
        type: 'RECEIVE_PRACTICE_LOCATION_PRODUCTS',
        practiceId,
        locationId,
        products,
    }
}

export function savingPracticeProducts(isSaving: boolean): SavingPracticeProducts {
    return {
        type: 'SAVING_PRACTICE_PRODUCTS',
        isSaving,
    }
}

export function savingPracticeLocationProducts(isSaving: boolean): SavingPracticeLocationProducts {
    return {
        type: 'SAVING_PRACTICE_LOCATION_PRODUCTS',
        isSaving,
    }
}

export function setCreateStep(step: PracticeCreateSteps, name?: string): SetCreateStep {
    return {
        type: 'SET_PRACTICE_CREATE_STEP',
        step,
        name,
    }
}

export function receivePracticeAgents(
    practice: Models.Practice,
    agents: Array<Api.PracticeAgent>,
): ReceivePracticeAgents {
    return {
        type: 'RECEIVE_PRACTICE_AGENTS',
        practice,
        agents,
    }
}

export function receivePracticeAgent(practice: Models.Practice, agent: Api.PracticeAgent): ReceivePracticeAgent {
    return {
        type: 'RECEIVE_PRACTICE_AGENT',
        practice,
        agent,
    }
}

export function receiveUnboundPracticeAgent(
    practice: Models.Practice,
    agent: Api.PracticeAgent,
): ReceiveUnboundPracticeAgent {
    return {
        type: 'RECEIVE_UNBOUND_PRACTICE_AGENT',
        practice,
        agent,
    }
}

export function deletePracticeLocation(
    practice: Models.Practice,
    location: Api.PracticeLocation,
): DeletePracticeLocation {
    return {
        type: 'DELETE_PRACTICE_LOCATION',
        practice,
        location,
    }
}

export function receiveAvailablePracticeUserRoles(roles: Models.PracticeUserRole[]): ReceivePracticeUserRoles {
    return {
        type: 'RECEIVE_AVAILABLE_PRACTICE_USER_ROLES',
        roles,
    }
}

export function updatePracticeSearchTerms(searchTerms: Models.SearchTerms): UpdatePracticeSearchTerms {
    return {
        type: 'UPDATE_PRACTICE_SEARCH_TERMS',
        searchTerms,
    }
}

export function receivePracticeLogo(practice: Models.Practice, logo?: Models.PracticeLogo): ReceivePracticeLogo {
    return {
        type: 'RECEIVE_PRACTICE_LOGO',
        practice,
        logo,
    }
}

export function receivePracticeLocationProcedures(
    locationId: string,
    practice: Models.Practice,
    procedures: Array<Models.Procedure>,
): ReceivePracticeLocationProcedures {
    return {
        type: 'RECEIVE_PRACTICE_LOCATION_PROCEDURES',
        locationId,
        practice,
        procedures,
    }
}

export function receiveProcedureProviders(
    locationId: string,
    practice: Models.Practice,
    procedure: Models.Procedure,
    providers: Models.Provider[],
): ReceivePracticeLocationProcedureProviders {
    return {
        type: 'RECEIVE_PRACTICE_LOCATION_PROCEDURE_PROVIDERS',
        locationId,
        practice,
        procedure,
        providers,
    }
}

export function receiveProcedureAvailability(
    location: Models.PracticeLocation,
    procedure: Models.Procedure,
    date: string,
    availability: Models.ProcedureAvailability,
): ReceiveProcedureAvailability {
    return {
        type: 'RECEIVE_PROCEDURE_AVAILABILITY',
        location,
        procedure,
        date,
        availability,
    }
}

export function receiveAvailability(
    location: Models.PracticeLocation,
    procedure: Models.Procedure,
    startDate: string,
    endDate: string,
    availability: Models.ProcedureAvailability,
): ReceiveAvailability {
    return {
        type: 'RECEIVE_AVAILABILITY',
        location,
        procedure,
        startDate,
        endDate,
        availability,
    }
}

export function receivePracticeHasEmailNotificationSubscribers(
    practice: Models.Practice,
    hasEmailNotificationSubscribers: Boolean,
): ReceivePracticeHasEmailNotificationSubscribers {
    return {
        type: 'RECEIVE_PRACTICE_HAS_EMAIL_NOTIFICATION_SUBSCRIBERS',
        practice,
        hasEmailNotificationSubscribers,
    }
}

export function receiveLocationSchedulingData(
    practice: Models.Practice,
    location: Models.PracticeLocation,
    schedulingData?: Api.SchedulingPracticeLocationJarvisInfo | Api.LocationScheduling,
): ReceiveLocationSchedulingData {
    return {
        type: 'RECEIVE_LOCATION_SCHEDULING_DATA',
        practice,
        location,
        schedulingData,
    }
}

export function receiveLocationConnectPaymentInfoData(
    practiceId: string,
    locationId: string,
    connectPaymentInfoData: Models.LocationConnectPaymentInfo | null,
): ReceiveLocationConnectPaymentInfoData {
    return {
        type: 'RECEIVE_LOCATION_CONNECT_PAYMENT_INFO_DATA',
        practiceId,
        locationId,
        connectPaymentInfoData,
    }
}

export function receiveLocationsSchedulingData(
    practiceId: string,
    schedulingData: ModelsV2.Practice.PracticeSchedulingLocationsData[],
): ReceiveLocationsSchedulingData {
    return {
        type: 'RECEIVE_LOCATIONS_SCHEDULING_DATA',
        practiceId,
        schedulingData,
    }
}

export function receivePracticeLocationsAgentModules(
    practiceId: string,
    schedulingData: ModelsV2.Practice.PracticeSchedulingLocationsData[],
): ReceivePracticeLocationsAgentModules {
    return {
        type: 'RECEIVE_PRACTICE_LOCATIONS_AGENT_MODULES',
        practiceId,
        schedulingData,
    }
}

export function receiveLocationPaymentsInfo(
    practiceId: string,
    locationId: string,
    paymentsInfo: Api.LocationPaymentsInfo,
): ReceiveLocationPaymentsInfo {
    return {
        type: 'RECEIVE_LOCATION_PAYMENTS_INFO',
        practiceId,
        locationId,
        paymentsInfo,
    }
}

export function receiveLocationStripeAccountId(
    practiceId: string,
    locationId: string,
    stripeAccountId?: string,
    stripeLocationId?: string,
): ReceiveLocationStripeAccountId {
    return {
        type: 'RECEIVE_LOCATION_STRIPE_ACCOUNT_ID',
        practiceId,
        locationId,
        stripeAccountId,
        stripeLocationId,
    }
}

export function receiveLocationStatementDescriptor(
    practiceId: string,
    locationId: string,
    statementDescriptor: string,
): ReceiveLocationStatementDescriptor {
    return {
        type: 'RECEIVE_LOCATION_STATEMENT_DESCRIPTOR',
        practiceId,
        locationId,
        statementDescriptor,
    }
}

export function updateAllLocationsStripeAccountId(
    practiceId: string,
    stripeAccountId: string,
): UpdateAllLocationsStripeAccountId {
    return {
        type: 'UPDATE_ALL_LOCATIONS_STRIPE_ACCOUNT_ID',
        practiceId,
        stripeAccountId,
    }
}

export function updateSingleLocationStripeAccountId(
    practiceId: string,
    locationId: string,
    stripeAccountId: string,
): UpdateSingleLocationStripeAccountId {
    return {
        type: 'UPDATE_SINGLE_LOCATION_STRIPE_ACCOUNT_ID',
        practiceId,
        locationId,
        stripeAccountId,
    }
}

export function removeLocationPaymentsTerminal(
    practiceId: string,
    locationId: string,
    terminalId: string,
): RemoveLocationPaymentsTerminal {
    return {
        type: 'REMOVE_LOCATION_PAYMENTS_TERMINAL',
        practiceId,
        locationId,
        terminalId,
    }
}

export function addLocationPaymentsTerminal(
    practiceId: string,
    locationId: string,
    terminalData: Models.LocationPaymentsTerminalInfo,
): AddLocationPaymentsTerminal {
    return {
        type: 'ADD_LOCATION_PAYMENTS_TERMINAL',
        practiceId,
        locationId,
        terminalData,
    }
}

export function receiveUserPhoneNumberBlackList(practiceId: string, accountId: string, phoneNumberBlackList: string[]) {
    return {
        type: 'RECEIVE_USER_PHONE_NUMBER_BLACK_LIST',
        practiceId,
        accountId,
        phoneNumberBlackList,
    }
}

export function setIsSavingPracticeStaff(practiceId: string, isSavingPracticeStaff: boolean) {
    return {
        type: 'SET_IS_SAVING_PRACTICE_STAFF',
        practiceId,
        isSavingPracticeStaff,
    }
}

export function fetchingAllPracticeLocations(practiceId: string, fetching: boolean) {
    return {
        type: 'FETCHING_ALL_PRACTICE_LOCATIONS',
        practiceId,
        fetching,
    }
}

export function createPractice(practice: Api.CreatePractice): any {
    return async (dispatch: any) => {
        if (!practice.timezone) {
            practice.timezone = defaultTimezone
        }
        const created = await Api.Practices.postCreate(practice)
        await dispatch(receivePractice(created))
        await dispatch(createdPractice(created))
        return created.id
    }
}

export function savePractice(practice: Models.Practice, updates: Api.UpdatePractice): any {
    return async (dispatch: any) => {
        const saved = await Api.Practices.putSave(practice, updates)
        await dispatch(savedPractice(saved))
        await dispatch(receivePractice(saved))
    }
}

export function resendInvitation(invitation: Api.InviteResend): any {
    return async () => {
        await Api.Practices.postResendInvitation(invitation)
    }
}

export function resendInvitationV2(invitation: ApiV2.Practice.InviteResend): any {
    return async () => {
        await ApiV2.Practice.postResendInvitation(invitation)
    }
}

export function saveAccount(practice: Models.Practice, accountId: string, account: ApiV2.Practice.AccountUpdate): any {
    return async (dispatch: any) => {
        try {
            dispatch(setIsSavingPracticeStaff(practice.id, true))
            const updated = await ApiV2.Practice.putSaveAccount(accountId, account)
            await dispatch(receiveAccount(practice, updated))
            const { hasEmailNotificationSubscribers } = await Api.Practices.hasEmailNotificationSubscribers(practice)
            dispatch(setIsSavingPracticeStaff(practice.id, false))
            await dispatch(receivePracticeHasEmailNotificationSubscribers(practice, hasEmailNotificationSubscribers))
        } catch (e) {
            dispatch(setIsSavingPracticeStaff(practice.id, false))
            throw e
        }
    }
}

export function createAccount(practice: Models.Practice, newAccount: ApiV2.Practice.AccountCreate): any {
    return async (dispatch: any) => {
        try {
            dispatch(setIsSavingPracticeStaff(practice.id, true))
            const created = await ApiV2.Practice.postCreateAccount(newAccount)
            dispatch(setIsSavingPracticeStaff(practice.id, false))
            if (created.id) {
                await dispatch(receiveAccount(practice, created))
            }
            return created
        } catch (e) {
            dispatch(setIsSavingPracticeStaff(practice.id, false))
            throw e
        }
    }
}

export function resetPassword(resetData: ApiV2.Practice.ResetPassword): any {
    return async () => {
        const resetPasswordResponse = await ApiV2.Practice.postResetPassword(resetData)
        return resetPasswordResponse
    }
}

export function createLocation(practiceId: string, location: ApiV2.Practice.CreatePracticeLocation): any {
    return async (dispatch: any) => {
        const created = await ApiV2.Practice.postCreatePracticeLocation(practiceId, location)
        return created
    }
}

export function saveLocation(
    practice: Models.Practice,
    location: Models.PracticeLocation,
    updates: Api.UpdateLocation,
): any {
    return async (dispatch: any) => {
        const updated = await Api.Practices.putSaveLocation(practice, location, updates)
        await dispatch(receivePracticeLocation(practice, updated))
    }
}

export function deleteLocation(practice: Models.Practice, location: Models.PracticeLocation): any {
    return async (dispatch: any) => {
        const deleted = await Api.Practices.putDeleteLocation(practice, location)
        await dispatch(deletePracticeLocation(practice, deleted))
    }
}

export function fetchPracticeWithLocations(practiceId: string): any {
    return async (dispatch: any) => {
        const practice = await Api.Practices.get(practiceId)
        dispatch(fetchingAllPracticeLocations(practiceId, true))
        const locations = await ApiV2.Practice.getAllPracticeLocations(practiceId)
        const mappedLocations = mapFullPracticeLocations(locations)
        await dispatch(receivePracticeWithLocations(practice, mappedLocations))
        dispatch(fetchingAllPracticeLocations(practiceId, false))
        await dispatch(fetchPracticeProductsByPracticeId(practiceId))
        return practice
    }
}

export function fetchPractice(practiceId: string): any {
    return async (dispatch: any) => {
        const practice = await Api.Practices.get(practiceId)
        await dispatch(receivePractice(practice))
        await dispatch(fetchPracticeProductsByPracticeId(practiceId))
        return practice
    }
}

export function fetchPracticeLocations(practiceId: string): any {
    return async (dispatch: any, getState: () => RootState) => {
        dispatch(fetchingAllPracticeLocations(practiceId, true))
        const locations = await ApiV2.Practice.getAllPracticeLocations(practiceId)
        const practice = getState().practices.practices[practiceId]
        const mappedLocations = practice
            ? mapFullPracticeLocationsWithProducts(locations, practice.products)
            : mapFullPracticeLocations(locations)

        await dispatch(receivePracticeLocationsWithOwnProducts(practiceId, mapFullPracticeLocations(locations)))
        await dispatch(receivePracticeLocations(practiceId, mappedLocations))
        dispatch(fetchingAllPracticeLocations(practiceId, false))
        return locations
    }
}

export function fetchPracticeLocationsPaginated(
    practice: Models.Practice,
    params: { search: string; page: number },
): any {
    return async (dispatch: any) => {
        const { data, metadata } = await ApiV2.Practice.getPracticeLocationsPaginated(practice.id, params)
        const mappedLocations = mapFullPracticeLocations(data)
        await dispatch(receivePracticeLocations(practice.id, mappedLocations))
        const allPages = metadata?.pagination_info?.allPages || 1
        const allRows = metadata?.pagination_info?.allPages || 0
        dispatch(setLocationListMeta(practice.id, { allPages, allRows }))
        return mappedLocations
    }
}

export function fetchLocationVyneProducts(practiceId: string, locationId: string) {
    return async (dispatch: any) => {
        try {
            const { data: products } = await ApiV2.Practice.getLocationVyneProducts(locationId)
            await dispatch(receiveLocationVyneProducts(practiceId, locationId, products))
            return products
        } catch (err) {
            await dispatch(receiveLocationVyneProducts(practiceId, locationId, []))
            return []
        }
    }
}

export function fetchPracticeLocationProcedures(locationId: string, practice: Models.Practice) {
    return async (dispatch: any) => {
        try {
            const procedures = await Api.Practices.listPracticeLocationProcedures(locationId, practice)
            await dispatch(receivePracticeLocationProcedures(locationId, practice, procedures))
            return procedures
        } catch (err) {
            return err
        }
    }
}

export function fetchProcedureProviders(locationId: string, practice: Models.Practice, procedure: Models.Procedure) {
    return async (dispatch: any) => {
        try {
            const providers = await Api.Practices.listProcedureProviders(locationId, practice, procedure)
            await dispatch(receiveProcedureProviders(locationId, practice, procedure, providers))
            return providers
        } catch (err) {
            return err
        }
    }
}

export function fetchProcedureAvailability(
    location: Models.PracticeLocation,
    procedure: Models.Procedure,
    date: string,
): any {
    return async (dispatch: any) => {
        try {
            const availability = await Api.Practices.listProcedureAvailability(location, procedure, date)
            await dispatch(receiveProcedureAvailability(location, procedure, date, availability))
            return availability
        } catch (err) {
            return err
        }
    }
}

export function fetchAvailability(
    location: Models.PracticeLocation,
    procedure: Models.Procedure,
    startDate: string,
    endDate: string,
    providerId?: string,
    tomorrow?: string,
): any {
    return async (dispatch: any) => {
        const endDateParam = tomorrow ? tomorrow : endDate
        try {
            const availability = await Api.Practices.listAvailability(
                location,
                procedure,
                startDate,
                endDateParam,
                providerId,
            )
            await dispatch(receiveAvailability(location, procedure, startDate, endDate, availability))
            return availability
        } catch (err) {
            throw err
        }
    }
}

export function fetchPracticeLogo(practice: Models.Practice): any {
    return async (dispatch: any) => {
        try {
            const { data: logo } = await Api.Practices.getPracticeLogo(practice)
            await dispatch(receivePracticeLogo(practice, logo))
        } catch (err) {
            await dispatch(receivePracticeLogo(practice))
        }
    }
}

export function confirmUploadedLogo(practice: Models.Practice, upload: Models.Upload): any {
    return async (dispatch: any) => {
        const logo = await Api.Practices.setPracticeLogo(practice, upload)
        await dispatch(fetchPracticeLogo(practice))
        return logo
    }
}

export function deletePracticeLogo(practice: Models.Practice): any {
    return async (dispatch: any) => {
        await Api.Practices.putDeleteLogo(practice)
        await dispatch(fetchPractice(practice.id))
        await dispatch(fetchPracticeLogo(practice))
    }
}

export function fetchPracticeList(searchTerms: Api.Amplify.PracticesSearchTerms = {}): any {
    return async (dispatch: any) => {
        try {
            const practicesListResponse = await Api.Practices.list(searchTerms)
            const practices = practicesListResponse.data || []
            const paginationInfo = practicesListResponse.paginationInfo || { allPages: 0, allRows: 0 }
            await dispatch(receivePracticeList(practices, paginationInfo))
            return practices
        } catch (err) {
            throw err
        }
    }
}

export function fetchPracticeProductsByPracticeId(practiceId: string): any {
    return async (dispatch: any) => {
        try {
            const practicesListResponse = await Api.Practices.list({ searchKey: practiceId })
            const practices = practicesListResponse.data || []
            if (practices.length === 1) {
                await dispatch(receivePracticeProducts(practiceId, practices[0].products || []))
            }
        } catch (err) {
            throw err
        }
    }
}

export function setPracticeSearchTerms(searchTerms: Api.Amplify.PracticesSearchTerms = {}): any {
    return async (dispatch: any) => {
        const stateSearchTerms = {
            page: searchTerms.page,
            searchKey: searchTerms.searchKey && decodeURIComponent(searchTerms.searchKey),
        }
        await dispatch(updatePracticeSearchTerms(stateSearchTerms))
    }
}

export function saveSelectedPractice(selectedPractice: SelectedPractice) {
    return async (dispatch: any) => {
        await dispatch(setSelectedPractice(selectedPractice))
    }
}

export function fetchPermissionList(): any {
    return async (dispatch: any) => {
        const permissions = permissionListData
        await dispatch(receivePermissionList(permissions))
    }
}

// TODO USE #LOC1# when load all practice(s) becomes shallow
// export function fetchPracticeLocations(practice: Models.Practice): any {
//     return async (dispatch: any) => {
//         const locations = await Api.Practices.getLocations(practice)
//         await dispatch(receivePracticeLocations(practice, locations))
//     }
// }

export function toggleDexVoice(practice: Models.Practice): any {
    return async (dispatch: any) => {
        const dexvoice = practice.products.find(p => p.id === Products.DexVoice)
        const product = {
            product_id: Products.DexVoice,
            active: dexvoice ? !dexvoice.active : true,
        }

        const products = await Api.Practices.putSaveProduct(practice, product)

        await dispatch(receivePracticeProducts(practice.id, products))
    }
}

export function savePracticeProduct(practice: Models.Practice, productsData: Api.SaveProductsData) {
    return async (dispatch: any) => {
        try {
            const products = await Api.Practices.putSaveProduct(practice, productsData)
            await dispatch(receivePracticeProducts(practice.id, products))
        } catch (err) {
            console.error(err)
        }
    }
}

export function savePracticeLocationProducts(
    practiceId: string,
    locationId: string,
    productsData: ApiV2.Practice.ProductForSave[],
) {
    return async (dispatch: any) => {
        try {
            const updatedProducts = await ApiV2.Practice.putUpdatePracticeLocationProducts(locationId, productsData)
            await dispatch(receivePracticeLocationProducts(practiceId, locationId, updatedProducts))
        } catch (err) {
            console.error(err)
        }
    }
}

export function impersonateUser(employee: ModelsV2.Practice.PracticeStaffData): AppThunk<Promise<void>> {
    return async (dispatch: AppDispatch) => {
        const url = await Api.Practices.postImpersonateUser(employee.id)
        window.open(url)
    }
}

export function fetchPracticeAgents(practice: Models.Practice) {
    return async (dispatch: any) => {
        const agents = await Api.Practices.listPracticeAgents(practice)
        await dispatch(receivePracticeAgents(practice, agents))
    }
}

export function updatePracticeAgent(
    practice: Models.Practice,
    agent: Models.PracticeAgent,
    update: Api.PracticeAgentUpdate,
): any {
    return async (dispatch: any) => {
        const updated = await Api.Practices.savePracticeAgent(agent, update)
        await dispatch(receivePracticeAgent(practice, updated))
    }
}

export function bindPracticeAgent(practice: Models.Practice, agentId: string, update: Api.PracticeAgentUpdate): any {
    return async (dispatch: any) => {
        const updated = await Api.Practices.savePracticeAgent(agentId, update)
        await dispatch(receivePracticeAgent(practice, updated))
    }
}

export function unbindPracticeAgent(practice: Models.Practice, agent: Models.PracticeAgent): any {
    return async (dispatch: any) => {
        const unboundAgent = await Api.Practices.unbindAgent(agent)
        await dispatch(receiveUnboundPracticeAgent(practice, unboundAgent))
    }
}

export function fetchAvailablePracticeUserRoles(): any {
    return async (dispatch: any) => {
        const { data: roles } = await Api.Practices.listAvailablePracticeUserRoles()
        await dispatch(receiveAvailablePracticeUserRoles(roles))
    }
}

export function fetchPracticeHasEmailNotificationSubscribers(practice: Models.Practice): any {
    return async (dispatch: any) => {
        const { hasEmailNotificationSubscribers } = await Api.Practices.hasEmailNotificationSubscribers(practice)
        await dispatch(receivePracticeHasEmailNotificationSubscribers(practice, hasEmailNotificationSubscribers))
    }
}

export function fetchLocationSchedulingData(practice: Models.Practice, location: Models.PracticeLocation): any {
    return async (dispatch: any) => {
        try {
            const { jarvis_location: schedulingDetails } = await Api.Practices.getLocationSchedulingData(location)
            await dispatch(receiveLocationSchedulingData(practice, location, schedulingDetails))
        } catch (e) {
            console.error(e)
        }
    }
}

export function fetchLocationConnectPaymentInfoData(practiceId: string, locationId: string): any {
    return async (dispatch: any) => {
        const connectPaymentInfo = await Api.Practices.getLocationConnectPaymentInfoData(locationId)
        await dispatch(receiveLocationConnectPaymentInfoData(practiceId, locationId, connectPaymentInfo || null))
    }
}

export function fetchLocationsSchedulingData(practiceId: string) {
    return async (dispatch: any) => {
        const schedulingDetails = await ApiV2.Practice.listAllSchedulingLocations(practiceId)
        await dispatch(receiveLocationsSchedulingData(practiceId, schedulingDetails))
    }
}

export function fetchPracticeLocationsSchedulingDataWithAgentModule(practiceId: string) {
    return async (dispatch: any) => {
        const schedulingDetails = await ApiV2.Practice.listAllSchedulingLocationsWithHeartbeatAgentModule(practiceId)
        await dispatch(receivePracticeLocationsAgentModules(practiceId, schedulingDetails))
    }
}

export function saveLocationScheduling(
    practice: Models.Practice,
    location: Models.PracticeLocation,
    updatesScheduling: Api.OptionalLocationScheduling,
): any {
    return async (dispatch: any) => {
        const schedulingDetails = await Api.Practices.saveLocationScheduling(location, updatesScheduling)
        await dispatch(receiveLocationSchedulingData(practice, location, schedulingDetails))
    }
}

export function loadLocationPaymentsInfo(practiceId: string, locationId: string) {
    return async (dispatch: any) => {
        const paymentsInfo = await Api.Payments.getLocationPaymentInfo(practiceId, locationId)
        await dispatch(receiveLocationPaymentsInfo(practiceId, locationId, paymentsInfo.data))
    }
}

export function deleteLocationPaymentsTerminal(practiceId: string, locationId: string, terminalId: string) {
    return async (dispatch: any) => {
        const deletedTerminal = await Api.Payments.deleteTerminal(practiceId, locationId, terminalId)
        await dispatch(removeLocationPaymentsTerminal(practiceId, locationId, terminalId))
        return deletedTerminal
    }
}

export function createLocationPaymentsTerminal(
    practiceId: string,
    locationId: string,
    terminalData: Models.LocationPaymentsTerminalInfo,
) {
    return async (dispatch: any) => {
        const createdTerminal = await Api.Payments.createTerminal(practiceId, locationId, terminalData)
        terminalData.id = createdTerminal.data.terminal.terminalDevice.terminalId
        await dispatch(addLocationPaymentsTerminal(practiceId, locationId, terminalData))
    }
}

export function createLocationConnectPaymentInfo(
    practiceId: string,
    locationId: string,
    createConnectPaymentInfo: Api.CreateLocationConnectPaymentInfo,
): any {
    return async (dispatch: any) => {
        const connectPaymentInfo = await Api.Practices.postCreateLocationConnectPaymentInfo(
            locationId,
            createConnectPaymentInfo,
        )
        await dispatch(receiveLocationConnectPaymentInfoData(practiceId, locationId, connectPaymentInfo))
    }
}

export function saveLocationConnectPaymentInfo(
    practiceId: string,
    locationId: string,
    updatesConnectPaymentInfo: Api.UpdateLocationConnectPaymentInfo,
): any {
    return async (dispatch: any) => {
        const connectPaymentInfo = await Api.Practices.putSaveLocationConnectPaymentInfo(
            locationId,
            updatesConnectPaymentInfo,
        )
        await dispatch(receiveLocationConnectPaymentInfoData(practiceId, locationId, connectPaymentInfo))
    }
}

export function validatePhoneNumber(phoneNumber: string): any {
    return async () => {
        try {
            const { isValid, message } = await Api.Practices.validatePhoneNumber(phoneNumber)
            return { isValid, message }
        } catch (err) {
            return err
        }
    }
}

export function fetchPhoneNumberBlackList(practiceId: string, accountId: string) {
    return async (dispatch: any) => {
        try {
            const { data: phoneNumberBlackList } = await Api.Practices.loadPhoneNumberBlackList(accountId)
            await dispatch(receiveUserPhoneNumberBlackList(practiceId, accountId, phoneNumberBlackList))
            await dispatch(receiveUserPhoneNumberBlackListV2(practiceId, accountId, phoneNumberBlackList))
        } catch (err) {
            return err
        }
    }
}

export function getZipCodeTimezone(zipCode: string): any {
    return async () => {
        try {
            const zipCodeDetails = await Api.App.getZipCodeTimezone(zipCode)
            return zipCodeDetails
        } catch (err) {
            throw err
        }
    }
}

export function fetchPracticeLogoBgColor(practiceId: string) {
    return async (dispatch: any): Promise<Models.LogoBgColorOptions> => {
        try {
            const logoBgData = await Api.Payments.fetchLogoBgColor(practiceId)
            if (logoBgData.data.logoBackgroundColorCode) {
                await dispatch(receivePracticeLogoBgColor(practiceId, logoBgData.data.logoBackgroundColorCode))
            }
            return logoBgData.data.logoBackgroundColorCode
        } catch (err) {
            return err
        }
    }
}

export function updateLogoBgColor(practiceId: string, bgColor: Models.LogoBgColorOptions) {
    return async (dispatch: any) => {
        try {
            return await Api.Payments.updateLogoBgColor(practiceId, bgColor)
        } catch (err) {
            throw err
        }
    }
}

export function fetchLocationStripeAccountId(practiceId: string, locationId: string): any {
    return async (dispatch: any): Promise<Api.PaymentsResponse<Api.LocationStripeAccountId>> => {
        try {
            // practiceId
            const locationStripeData = await Api.Payments.fetchLocationStripeAccountId(practiceId, locationId)
            if (locationStripeData.data.stripeConnectedAccountId) {
                dispatch(
                    receiveLocationStripeAccountId(
                        practiceId,
                        locationId,
                        locationStripeData.data.stripeConnectedAccountId,
                        locationStripeData.data.stripeLocationId,
                    ),
                )
            }
            return locationStripeData
            // if (logoBgData.data.logoBackgroundColorCode) {
            //     await dispatch(receivePracticeLogoBgColor(practiceId, logoBgData.data.logoBackgroundColorCode))
            // }
            // return logoBgData.data.logoBackgroundColorCode
        } catch (err) {
            return err
        }
    }
}

export function updateStripeAccountIdAllLocations(practiceId: string, stripeAccountId: string): any {
    return async (dispatch: any) => {
        try {
            await Api.Payments.updateStripeAccountIdAllLocations(practiceId, stripeAccountId)
            dispatch(updateAllLocationsStripeAccountId(practiceId, stripeAccountId))
            return
        } catch (err) {
            throw err
        }
    }
}

export function updateStripeAccountIdSingleLocation(
    practiceId: string,
    locationId: string,
    stripeAccountId: string,
): any {
    return async (dispatch: any) => {
        try {
            await Api.Payments.updateStripeAccountIdSingleLocation(practiceId, locationId, stripeAccountId)
            dispatch(updateSingleLocationStripeAccountId(practiceId, locationId, stripeAccountId))
            return
        } catch (err) {
            throw err
        }
    }
}

// start payments rates actions

// export function receivePaymentProcessingData(
//     processingData: Api.PaymentProcessingResponse,
//     practice: Models.Practice,
// ): FetchPaymentProcessingData {
//     return {
//         type: 'RECEIVE_PAYMENT_PROCESSOR_DATA',
//         processingData,
//         practice,
//     }
// }
export function fetchPaymentProcessingData(practiceId: string, locationId: string): any {
    return async (dispatch: any): Promise<Api.PaymentProcessingResponse> => {
        const {
            data: processingData,
        }: { data: Api.PaymentProcessingResponse } = await Api.Payments.loadPayProcessingData(practiceId, locationId)
        // await dispatch(receivePaymentProcessingData(processingData, practice))
        return processingData
    }
}

export function savePaymentProcessingData(
    practiceId: string,
    locationId: string,
    merchantFee: number,
    merchantProcessingRate: number,
    terminalFee: number,
    terminalProcessingRate: number,
    debitFee: number,
    debitProcessingRate: number,
    insuranceFee: number,
    insuranceProcessingRate: number,
    achFee: number,
    achProcessingRate: number,
    membershipPlanRate: number,
    monthlyPlatformFee: number,
    monthlyTerminalFee: number,
    prepaidProcessingRate: number,
    prepaidFlatFee: number,
    type: Models.PaymentProcessingType,
    applyToAll: boolean,
): any {
    return async (dispatch: any) => {
        let payProcessing
        if (!applyToAll) {
            payProcessing = await Api.Payments.postLocationPayProcessingData(
                practiceId,
                locationId,
                merchantFee,
                merchantProcessingRate,
                terminalFee,
                terminalProcessingRate,
                debitFee,
                debitProcessingRate,
                insuranceFee,
                insuranceProcessingRate,
                achFee,
                achProcessingRate,
                membershipPlanRate,
                monthlyPlatformFee,
                monthlyTerminalFee,
                prepaidProcessingRate,
                prepaidFlatFee,
                type,
            )
        } else {
            payProcessing = await Api.Payments.postPracticePayProcessingData(
                practiceId,
                merchantFee,
                merchantProcessingRate,
                terminalFee,
                terminalProcessingRate,
                debitFee,
                debitProcessingRate,
                insuranceFee,
                insuranceProcessingRate,
                achFee,
                achProcessingRate,
                membershipPlanRate,
                monthlyPlatformFee,
                monthlyTerminalFee,
                prepaidProcessingRate,
                prepaidFlatFee,
                type,
            )
        }
        return payProcessing
    }
}

// end payments rates actions

export const getLocationLogo = (practiceId: string, locationId: string) => {
    return async (dispatch: any): Promise<Api.PaymentsResponse<Api.PaymentLocationLogoResponse>> => {
        try {
            const logoData = await Api.Payments.getLocationLogo(practiceId, locationId)
            return logoData
        } catch (err) {
            throw err
        }
    }
}

export const updateLocationLogoUploadId = (practiceId: string, locationId: string, uploadId: string) => {
    return async (dispatch: any): Promise<any> => {
        try {
            const logoData = await Api.Payments.updateLocationLogo(practiceId, locationId, uploadId)
            return logoData
        } catch (err) {
            throw err
        }
    }
}

export const deleteLocationLogo = (practiceId: string, locationId: string) => {
    return async (dispatch: any): Promise<any> => {
        try {
            const deletedLogo = await Api.Payments.deleteLocationLogo(practiceId, locationId)
            return deletedLogo
        } catch (err) {
            throw err
        }
    }
}

export const updateLocationLogoBgColor = (practiceId: string, locationId: string, bgColorHex: string) => {
    return async (dispatch: any) => {
        try {
            const udpatedLogoBgColor = await Api.Payments.updateLocationLogoBgColor(practiceId, locationId, bgColorHex)
            return udpatedLogoBgColor
        } catch (err) {
            throw err
        }
    }
}

export function updateLocationCompletionStatus(completionStatus: any, locationId: string, practiceId: string): any {
    for (const field in completionStatus) {
        if (!completionStatus[field]) {
            return {
                type: 'UPDATE_LOCATION_COMPLETION_STATUS',
                locationId,
                practiceId,
                allFieldsCompleted: false,
                completionStatus,
            }
        }
    }
    return {
        type: 'UPDATE_LOCATION_COMPLETION_STATUS',
        locationId,
        practiceId,
        allFieldsCompleted: true,
        completionStatus,
    }
}
export const getLocationMilestone = (practiceId: string, locationId: string) => {
    return async (dispatch: any): Promise<Api.LocationMilestoneDataResponse> => {
        try {
            const milestone = await Api.Practices.getMilestoneData(practiceId, locationId)
            // const milestone = { practiceId, locationId, trainingComplete: true }
            await dispatch(
                receiveLocationMilestone(milestone.practiceId, milestone.locationId, milestone.trainingComplete),
            )
            return milestone
        } catch (err) {
            throw err
        }
    }
}

export const getAllLocationsPaymentsStatus = (practiceId: string) => {
    return async (dispatch: any): Promise<Api.CompletionStatusResponse> => {
        try {
            const response = await Api.Practices.getAllPaymentsStatusData(practiceId)
            // const milestones = [{ practiceId, locationId: 'vRwJZW28pOaz2IH1', trainingComplete: true }]
            response.milestoneResponse.forEach(
                (milestone: { practiceId: string; locationId: string; trainingComplete: boolean }) =>
                    dispatch(
                        receiveLocationMilestone(
                            milestone.practiceId,
                            milestone.locationId,
                            milestone.trainingComplete,
                        ),
                    ),
            )
            response.stripeResponse.forEach(
                (loc: { practiceId: string; locationId: string; stripeConnectedAccountId: string }) => {
                    if (loc.stripeConnectedAccountId) {
                        dispatch(
                            receiveLocationStripeAccountId(
                                loc.practiceId,
                                loc.locationId,
                                loc.stripeConnectedAccountId,
                            ),
                        )
                    }
                },
            )
            response.ratesResponse.forEach((ratesData: Api.PaymentProcessingResponse) => {
                if (
                    ratesData.flatFee === undefined ||
                    ratesData.processingRate === undefined ||
                    ratesData.convenienceFee === undefined ||
                    ratesData.terminalFlatFee === undefined ||
                    ratesData.terminalProcessingRate === undefined ||
                    ratesData.debitFlatFee === undefined ||
                    ratesData.debitProcessingRate === undefined ||
                    ratesData.insuranceFlatFee === undefined ||
                    ratesData.insuranceProcessingRate === undefined ||
                    ratesData.achFlatFee === undefined ||
                    ratesData.achProcessingRate === undefined ||
                    ratesData.membershipPlanProcessingRate === undefined ||
                    ratesData.monthlyPlatformFee === undefined ||
                    ratesData.monthlyTerminalFee === undefined
                ) {
                    dispatch(receivePaymentRatesStatus(ratesData.practiceId, ratesData.locationId, false))
                } else {
                    dispatch(receivePaymentRatesStatus(ratesData.practiceId, ratesData.locationId, true))
                }
            })
            return response
        } catch (err) {
            throw err
        }
    }
}

export const receivePaymentRatesStatus = (
    practiceId: string,
    locationId: string,
    status: boolean,
): UpdatePaymentRatesStatus => {
    return {
        type: 'UPDATE_PAYMENT_RATES_STATUS',
        practiceId,
        locationId,
        status,
    }
}

export const updateLocationMilestone = (practiceId: string, locationId: string, isCompleted: boolean) => {
    return async (dispatch: any): Promise<Api.LocationMilestoneDataResponse> => {
        try {
            const updatedMilestone = await Api.Practices.putTrainingComplete(practiceId, locationId, isCompleted)
            // const updatedMilestone = { practiceId, locationId, trainingComplete: isCompleted }
            await dispatch(
                receiveLocationMilestone(
                    updatedMilestone.practiceId,
                    updatedMilestone.locationId,
                    updatedMilestone.trainingComplete,
                ),
            )
            return updatedMilestone
        } catch (err) {
            throw err
        }
    }
}

export const receiveLocationMilestone = (
    practiceId: string,
    locationId: string,
    isCompleted: boolean,
): ReceiveLocationMilestone => {
    return {
        type: 'RECEIVE_LOCATION_MILESTONE',
        practiceId,
        locationId,
        isCompleted,
    }
}

export const addNewLocationProduct = (locationId: string, product: ApiV2.Practice.ProductForSave) => {
    return async (dispatch: any): Promise<ApiV2.Practice.ProductForSave> => {
        try {
            const newProduct = await ApiV2.Practice.postCreatePracticeLocationProduct(locationId, product)
            return newProduct
        } catch (err) {
            throw err
        }
    }
}
