import React, { useState } from 'react'
import Checkbox, { CheckboxClassKey } from '@mui/material/Checkbox'
import FormControlLabel, { FormControlLabelClassKey } from '@mui/material/FormControlLabel'

import './SelfSchedulingWebCodeLocation.sass'

const moduleName = 'self-scheduling-web-code-location'

type FormControlLabelClassOverrides = {
    readonly [key in FormControlLabelClassKey]?: string
}

type CheckboxClassOverrides = {
    readonly [key in CheckboxClassKey]?: string
}

const formControlLabelClassOverrides: FormControlLabelClassOverrides = {
    root: `${moduleName}__form-control-root`,
    label: `${moduleName}__form-control-label`,
}

const checkboxClassOverrides: CheckboxClassOverrides = {
    root: `${moduleName}__checkbox-root`,
}

export type Props = {
    location: Models.SelfSchedulingWebCodeLocation
    updateLocation: (locationId: string, enabled: boolean) => void
}

const SelfSchedulingWebCodeLocation = ({ location, updateLocation }: Props) => {
    const [loading, setLoading] = useState<boolean>(false)

    const handleOnChange = async () => {
        setLoading(true)
        await updateLocation(location.practiceLocationId, !location.enabled)
        setLoading(false)
    }

    return (
        <div className={moduleName}>
            <div className={`${moduleName}__location-section`}>
                <FormControlLabel
                    classes={formControlLabelClassOverrides}
                    control={
                        <Checkbox
                            disabled={loading}
                            classes={checkboxClassOverrides}
                            checked={location.enabled}
                            onChange={handleOnChange}
                            value={location.practiceLocationId}
                        />
                    }
                    label={''}
                />

                <label className={`${moduleName}__label ${moduleName}__label--checkbox`}>
                    <div className={`${moduleName}__text`}>{location.name}</div>
                </label>
            </div>
        </div>
    )
}

export default SelfSchedulingWebCodeLocation
