import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import LinkifyIt from 'linkify-it'
import debounce from 'lodash/debounce'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import tlds from 'tlds'
import xss from 'xss'

import { RootState } from '../../../appReducer'
import NotificationService from '../../../services/NotificationService'
import PubNubService from '../../../services/PubNubService'
import { useAppDispatch } from '../../../util/useAppDispatch'
import { usePrevious } from '../../shared/custom-hooks'
import CustomProgressBar from '../../shared/custom-progress-bar/CustomProgressBar'
import { fetchAllNewerChatMsgs } from '../actions'
import NewMessageIndicator from '../NewMessageIndicator'
import SystemMessage from '../SystemMessage'

import './ChatConversation.sass'

export type Props = {
    selectedChat: Models.ChatCenterSelectedChat
    isBanned: boolean | undefined
    pendingMessages: Models.Message[]
    removePendingMessage: (message: Models.Message) => void
}

const urlify = (text: string) => {
    const sanitizedText = xss(text)

    const linkify = new LinkifyIt()
    linkify.tlds(tlds)

    const matches = linkify.match(sanitizedText)

    if (!matches) {
        return sanitizedText
    }

    const elements: string[] = []
    let lastIndex = 0
    matches.forEach(match => {
        if (match.index > lastIndex) {
            elements.push(sanitizedText.substring(lastIndex, match.index))
        }
        elements.push(`<a href="${match.url}" target="_blank">${match.text}</a>`)
        lastIndex = match.lastIndex
    })

    if (sanitizedText.length > lastIndex) {
        elements.push(sanitizedText.substring(lastIndex))
    }

    return elements.join('')
}

const getMessageStyle = (message: Models.Message) => {
    if (message.type === 'whisper') {
        return 'whisper-message'
    }

    return message.is_patient ? 'patient-message' : 'practice-message'
}

const getTimestampStyle = (message: Models.Message) => {
    return !message.is_patient ? 'practice-timestamp' : ''
}

const formatTimestamp = (message: Models.Message, selectedChat: Models.ChatMetadata): string => {
    const { claimee, patientName } = selectedChat
    const timestamp = moment.unix(Math.floor(Number(message.timetoken) / 10000000)).format('h:mm a')
    let formattedTimestamp = ''
    const senderName = message.sender_name ? message.sender_name : claimee && claimee.firstName ? claimee.firstName : ''

    if (message.is_patient) {
        formattedTimestamp = `${patientName ? patientName + '@' : ''}${timestamp}`
    } else {
        formattedTimestamp = `${senderName ? senderName + '@' : ''}${timestamp}`
    }

    return formattedTimestamp
}

const showTimestamp = (message: Models.Message, selectedChat: Models.ChatMetadata): string => {
    if (message.status === 'pending') {
        return 'Sending...'
    }

    return formatTimestamp(message, selectedChat)
}

const ChatConversation = (props: Props) => {
    const { selectedChat, isBanned } = props
    const conversation = useSelector((state: RootState) => state.chat.conversations[props.selectedChat.channelName])
    const account = useSelector((state: RootState) => state.app.self && state.app.self.account)
    const messages = conversation?.messages || []

    const prevMessages = usePrevious(messages) || null

    const lastMessage = messages[messages.length - 1]
    const isLastMessagePatientMsg = lastMessage?.is_patient
    const lastMessageId = lastMessage?.id

    const bottomAnchorRef = useRef<HTMLDivElement>(null)
    const chatFeedRef = useRef<HTMLDivElement>(null)
    const [missedMessages, setMissedMessages] = useState<number>(0)
    const [initScroll, setInitScroll] = useState<boolean>(true)
    const [lastPatientMessageId, setLastPatientMessageId] = useState<string>('')

    const whispers = useSelector(
        (state: RootState) => state.chat.conversations[`whisper_${props.selectedChat.channelName}`],
    )

    const dispatch = useAppDispatch()

    const patientScrollThreshold = 200
    const whisperMessagesLength = whispers?.messages.length ?? 0

    useEffect(() => {
        const lastMessage = messages[messages.length - 1]
        if (
            prevMessages !== null &&
            messages.length > prevMessages.length &&
            lastMessage.type === 'text' &&
            lastMessage.status === 'success' &&
            lastMessage.is_patient
        ) {
            NotificationService.playSound('user-message')
        }
    }, [messages, prevMessages])

    useEffect(() => {
        if (!selectedChat.initialized && selectedChat.visible) {
            const whisperChannelName = `whisper_${selectedChat.channelName}`
            const pubnubService = PubNubService.getInstance(account?.id || 'none')
            pubnubService.subscribe([selectedChat.channelName])
            pubnubService.subscribe([whisperChannelName], false)

            dispatch(fetchAllNewerChatMsgs(selectedChat.channelName))
            dispatch(fetchAllNewerChatMsgs(whisperChannelName))
        }
    }, [dispatch, selectedChat])

    const scrollToTheBottom = (isPatientMsg = false, lastMessageId = '', lastPatientMessageIdentifier = '') => {
        const conversationRef = chatFeedRef.current
        const anchor = bottomAnchorRef.current

        if (conversationRef && anchor) {
            if (isPatientMsg) {
                const conversationBox = conversationRef.getBoundingClientRect()
                const anchorBox = anchor.getBoundingClientRect()
                const anchorConversationDiff = anchorBox.bottom - conversationBox.bottom

                if (anchorConversationDiff < patientScrollThreshold) {
                    setMissedMessages(0)
                    anchor.scrollIntoView()
                } else {
                    if (lastMessageId !== lastPatientMessageIdentifier) {
                        setLastPatientMessageId(lastMessageId)
                        setMissedMessages(c => c + 1)
                    }
                }
            } else {
                setMissedMessages(0)
                anchor.scrollIntoView()
            }
        }
    }

    const debouncedScrollToTheBottom = useCallback(
        debounce(
            (isPatientMsg: boolean = false, lastMessageId: string = '', lastPatientMessageIdentifier: string = '') =>
                scrollToTheBottom(isPatientMsg, lastMessageId, lastPatientMessageIdentifier),
            500,
        ),
        [],
    )

    useEffect(() => {
        if (initScroll && messages.length > 0) {
            const anchor = bottomAnchorRef.current
            setInitScroll(false)
            anchor?.scrollIntoView()
        }
    }, [messages, initScroll])

    useEffect(() => {
        debouncedScrollToTheBottom()
    }, [debouncedScrollToTheBottom, whisperMessagesLength])

    useEffect(() => {
        if (isLastMessagePatientMsg) {
            debouncedScrollToTheBottom(isLastMessagePatientMsg, lastMessageId, lastPatientMessageId)
        }
    }, [debouncedScrollToTheBottom, lastPatientMessageId, lastMessageId, isLastMessagePatientMsg])

    const getMessages = () => {
        const whisperMessages = ((whispers && whispers.messages) || []).map(m => ({
            ...m,
            type: 'whisper',
        }))
        const filteredPendingMessages = filterPendingMessages(messages, props.pendingMessages)
        return sortBy([...messages, ...filteredPendingMessages, ...whisperMessages], m => m.timetoken)
    }

    const filterPendingMessages = (messages: Models.Message[], pendingMessages: Models.Message[]) => {
        const messagesIds = messages.map(message => message.id)
        return pendingMessages.filter(pendingMessage => {
            const isPendingMessageSent = messagesIds.includes(pendingMessage.id)
            if (isPendingMessageSent) {
                props.removePendingMessage(pendingMessage)
            }
            return !isPendingMessageSent
        })
    }

    const renderMessage = (message: Models.Message) => {
        if (message.type?.startsWith('system:')) {
            return <SystemMessage key={message.id} message={message} />
        }

        return (
            <React.Fragment key={message.id}>
                <div
                    className={classNames('message', getMessageStyle(message))}
                    dangerouslySetInnerHTML={{ __html: urlify(message.text) }}
                />
                <span className={classNames('timestamp', getTimestampStyle(message))}>
                    {message.status && message.status === 'pending' && (
                        <CustomProgressBar scrollToTheBottom={scrollToTheBottom} />
                    )}
                    {showTimestamp(message, selectedChat)}
                </span>
            </React.Fragment>
        )
    }

    const onChatFeedScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const chatFeedRefCurrent = e.currentTarget

        if (!chatFeedRefCurrent) {
            return
        }

        const scrolled = chatFeedRefCurrent.scrollTop + chatFeedRefCurrent.clientHeight
        const scrollHeight = chatFeedRefCurrent.scrollHeight
        const pixelsFromBottom = scrollHeight - scrolled

        if (pixelsFromBottom <= patientScrollThreshold) {
            setMissedMessages(0)
        }
    }

    const opacityStyle = isBanned ? { opacity: 0.5 } : { opacity: 1 }

    return (
        <div className="chat-tile-conversation">
            <div className="messages" ref={chatFeedRef} style={opacityStyle} onScroll={onChatFeedScroll}>
                {getMessages().map(renderMessage)}
                <div className="bottom-anchor" ref={bottomAnchorRef} />
            </div>

            {conversation?.preview && <div className="message-preview">{conversation.preview}</div>}

            <NewMessageIndicator numberOfMessages={missedMessages} onClick={debouncedScrollToTheBottom} />
        </div>
    )
}

export default ChatConversation
