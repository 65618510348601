import React, { Fragment, useCallback, useEffect, useReducer, useRef } from 'react'
import ReactLoading from 'react-loading'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import cx from 'classnames'
import moment, { Moment } from 'moment'

import './CalendarPicker.sass'

const DATE_FORMAT = 'YYYY-MM-DD'

export type AvailableSlots = {
    [key: string]: string[]
}

enum MonthStatus {
    LOADING = 'LOADING', // Loading 1st time
    NO_SLOTS = 'NO_SLOTS', // Loaded but no days with free slots
    LOADED = 'LOADED', // Loaded and there are some available days
    ERROR = 'ERROR', // Error fetching time slots
}

type MonthsStatuses = {
    [key: string]: MonthStatus
}

interface Reducer {
    pageStatus: MonthsStatuses
    currentMonth: Moment
    startDate: Moment
    endDate: Moment
    selectedDay?: Moment
}

const today = moment().startOf('day')

const getStartDate = (date: Moment) =>
    date
        .clone()
        .startOf('month')
        .startOf('week')

const getEndDate = (date: Moment) =>
    date
        .clone()
        .endOf('month')
        .endOf('week')

const initialState: Reducer = {
    pageStatus: {},
    currentMonth: today,
    startDate: getStartDate(today),
    endDate: getEndDate(today),
}

const reducer = (state: Reducer, action: any) => {
    switch (action.type) {
        case 'setMonth':
            return {
                ...state,
                currentMonth: action.payload,
                startDate: getStartDate(action.payload),
                endDate: getEndDate(action.payload),
            }
        case 'selectDay': {
            return {
                ...state,
                selectedDay: action.payload,
            }
        }
        case 'updatePageStatus':
            return { ...state, pageStatus: { ...state.pageStatus, [action.key]: action.status } }
        default:
            return state
    }
}

interface Props {
    onConfirm: (date: Moment) => void
    getAvailableDays: (start: Moment, end: Moment) => Promise<AvailableSlots>
    availableSlots?: AvailableSlots
}

const moduleName = 'calendar-picker'

const CalendarPicker = ({ onConfirm, availableSlots, getAvailableDays }: Props) => {
    const isComponentMounted = useRef(true)

    const [{ pageStatus, currentMonth, startDate, endDate, selectedDay }, dispatch] = useReducer(reducer, initialState)

    const monthKey = currentMonth.format('YYYY-MM')
    const currentMonthStatus = pageStatus[monthKey]

    useEffect(() => {
        isComponentMounted.current = true
        return () => {
            isComponentMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (
            selectedDay &&
            availableSlots?.[selectedDay.format(DATE_FORMAT)]?.length &&
            selectedDay.isSameOrAfter(startDate) &&
            selectedDay.isSameOrBefore(endDate)
        ) {
            return
        }
        if (availableSlots) {
            const acceptableDateFrom = (startDate.isSameOrAfter(today) ? startDate : today).format(DATE_FORMAT)
            const acceptableDateTo = endDate.format(DATE_FORMAT)
            const acceptableDates = Object.keys(availableSlots).filter(
                date => availableSlots[date].length && date >= acceptableDateFrom && date <= acceptableDateTo,
            )
            if (
                (!acceptableDates.length && selectedDay) ||
                (acceptableDates.length && (selectedDay?.isBefore(startDate) || selectedDay?.isAfter(endDate)))
            ) {
                dispatch({
                    type: 'selectDay',
                    payload: undefined,
                })
            }
            if (acceptableDates.length && !selectedDay) {
                dispatch({
                    type: 'selectDay',
                    payload: moment(acceptableDates.sort()[0], DATE_FORMAT),
                })
            }
        }
    }, [availableSlots, selectedDay, startDate, endDate])

    const setCurrentDateHandler = (date: Moment) => {
        dispatch({
            type: 'setMonth',
            payload: date,
        })
    }

    const loadSlots = useCallback(() => {
        if (isComponentMounted) {
            dispatch({
                type: 'updatePageStatus',
                key: monthKey,
                status: MonthStatus.LOADING,
            })

            getAvailableDays(startDate, endDate)
                .then(availability => {
                    dispatch({
                        type: 'updatePageStatus',
                        key: monthKey,
                        status: availability.length ? MonthStatus.LOADED : MonthStatus.NO_SLOTS,
                    })
                })
                .catch(() => {
                    dispatch({
                        type: 'updatePageStatus',
                        key: monthKey,
                        status: MonthStatus.ERROR,
                    })
                })
        }
    }, [endDate, getAvailableDays, monthKey, startDate])

    useEffect(() => {
        if (
            ![MonthStatus.LOADING, MonthStatus.LOADED, MonthStatus.NO_SLOTS, MonthStatus.ERROR].includes(
                pageStatus[monthKey],
            )
        ) {
            loadSlots()
        }
    }, [currentMonth, loadSlots, monthKey, pageStatus, startDate])

    const isFirstMonth = currentMonth.isSameOrBefore(today)

    const nextMonth = () => setCurrentDateHandler(currentMonth.clone().add(1, 'month'))

    const prevMonth = () => !isFirstMonth && setCurrentDateHandler(currentMonth.clone().add(-1, 'month'))

    const handleRefresh = () => loadSlots()

    const header = () => {
        return (
            <div className={`${moduleName}__header`}>
                <div
                    className={`${moduleName}__header-arrow ${
                        isFirstMonth ? `${moduleName}__header-arrow--disabled` : ''
                    }`}
                >
                    <div className={`${moduleName}__header-icon`} onClick={prevMonth}>
                        <Icon>chevron_left</Icon>
                    </div>
                </div>
                <div className={`${moduleName}__header-label`}>
                    <span>{currentMonth.format('MMMM YYYY')}</span>
                </div>
                <div className={`${moduleName}__header-arrow`}>
                    <div className={`${moduleName}__header-icon`} onClick={nextMonth}>
                        <Icon>chevron_right</Icon>
                    </div>
                </div>
            </div>
        )
    }

    const daysOfWeek = () => {
        const dateFormat = 'dd'
        const labels = { Su: 'S', Mo: 'M', Tu: 'T', We: 'W', Th: 'Th', Fr: 'F', Sa: 'Sa' }
        const days = []
        const firstDate = currentMonth.clone().startOf('week')

        for (let i = 0; i < 7; i++) {
            days.push(
                <div className={`${moduleName}__weekday`} key={i}>
                    {
                        labels[
                            moment(firstDate)
                                .add(i, 'day')
                                .format(dateFormat)
                        ]
                    }
                </div>,
            )
        }

        return <div className={`${moduleName}__weekdays`}>{days}</div>
    }

    const days = () => {
        const dayFormat = 'D'
        const rows = []
        let days = []
        let day = startDate.clone()

        const selectDay = (day: Moment) => () => dispatch({ type: 'selectDay', payload: day })

        while (day.isSameOrBefore(endDate)) {
            for (let i = 0; i < 7; i++) {
                const key = day.format(DATE_FORMAT)
                days.push(
                    <div className={cx(`${moduleName}__col`, `${moduleName}__cell`)} key={key}>
                        <button
                            disabled={day.isBefore(today, 'day') || !availableSlots?.[key]?.length}
                            onClick={selectDay(day.clone())}
                            className={cx(
                                `${moduleName}__day`,
                                selectedDay && day.isSame(selectedDay, 'day') && `${moduleName}__day--selected`,
                            )}
                        >
                            {day.format(dayFormat)}
                        </button>
                    </div>,
                )
                day = day.add(1, 'day')
            }

            rows.push(
                <div className={`${moduleName}__row`} key={day.toISOString()}>
                    {' '}
                    {days}{' '}
                </div>,
            )
            days = []
        }
        return <div className={`${moduleName}__days`}>{rows}</div>
    }

    const handleNext = () => {
        selectedDay && onConfirm(selectedDay)
    }

    return (
        <Fragment>
            <button
                disabled={currentMonthStatus === MonthStatus.LOADING}
                className={`${moduleName}__refresh`}
                onClick={handleRefresh}
            >
                Refresh Schedule
                <Icon>refresh</Icon>
            </button>

            <div className={moduleName}>
                {[MonthStatus.LOADING].includes(currentMonthStatus) && (
                    <div className={`${moduleName}__loader`}>
                        <ReactLoading type="spin" />
                    </div>
                )}

                {[MonthStatus.NO_SLOTS].includes(currentMonthStatus) && (
                    <div className={`${moduleName}__message`}>
                        Sorry, no {currentMonth.format('MMMM')} appointments available
                    </div>
                )}

                {[MonthStatus.ERROR].includes(currentMonthStatus) && (
                    <div className={`${moduleName}__message ${moduleName}__message--error`}>
                        Sorry, an error occurred. Try to <em onClick={handleRefresh}>refresh</em> Schedule.
                    </div>
                )}

                {header()}
                {daysOfWeek()}
                {days()}
                <div className={`${moduleName}__footer`}>
                    <Button disabled={!selectedDay} onClick={handleNext}>
                        NEXT
                    </Button>
                </div>
            </div>
        </Fragment>
    )
}

export default CalendarPicker
