import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import Slider from 'react-slick'
import { Modal } from '@mui/material'
import Grid from '@mui/material/Grid'
import _ from 'lodash'
import moment from 'moment'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { RootState } from '../../appReducer'
import AdminAccount from '../../models/AdminAccount'
import PubNubService from '../../services/PubNubService'
import { useAppDispatch } from '../../util/useAppDispatch'
import { useInterval } from '../shared/custom-hooks'
import DisconnectInterface from '../shared/disconnect-interface/DisconnectInterface'

import ChatList from './chat-list/ChatList'
import ChatTile from './chat-tile/ChatTile'
import useChatSlider from './chat-tiles-container-hooks/useChatSlider'
import { fetchBrowsingCount, fetchShortcuts, fetchUnclaimedChats, setSelectedChats, turnChatsPage } from './actions'

import './ChatCenter.sass'

const moduleName = 'chat-center'

export type Props = {
    numChatsOnScreen: number
}

const ChatCenter = (props: Props) => {
    const slider = useRef<Slider>()
    const account = useSelector((state: RootState) => (state.app.self && state.app.self.account) as AdminAccount)
    const selectedChats = useSelector((state: RootState) => state.chat.selectedChats)
    const claimedChats = useSelector((state: RootState) => state.chat.claimedChats)
    const isNetworkUp = useSelector((state: RootState) => state.chat.isNetworkUp)
    const webCodes = useSelector((state: RootState) => state.amplify.webCodes)
    const numChatsOnScreen = useSelector((state: RootState) => state.chat.numChatsOnScreen)
    const paginator = useSelector((state: RootState) => state.chat.paginator)

    const dispatch = useAppDispatch()

    const fetchBrowsingCountInterval = () => {
        dispatch(fetchBrowsingCount())
    }

    useEffect(() => {
        dispatch(fetchBrowsingCount())
        dispatch(fetchShortcuts())
        dispatch(fetchUnclaimedChats())
    }, [dispatch])

    useInterval(fetchBrowsingCountInterval, moment.duration(1, 'minute').asMilliseconds())

    const myClaimedChats = useMemo(() => _.orderBy(claimedChats, ['created'], ['desc']), [claimedChats])

    const { activeSlide, focusChat, focusedChatIndex, setActiveCarouselSlide } = useChatSlider({
        slider,
        selectedChats,
        numChatsOnScreen,
        paginator,
        turnChatsPage,
    })

    const attachChats = useCallback(
        (chats: Models.ChatMetadata[]) => {
            const selectedChatsIds = selectedChats.map(chat => chat.id)
            const newSelectedChats = chats
                .filter(({ id }) => !selectedChatsIds.includes(id))
                .map(chat => ({
                    ...chat,
                    visible: false,
                    initialized: false,
                    rendered: false,
                    patientRejoinedChatCounter: 0,
                    isPatientOnline: false,
                }))

            const allChats = [...selectedChats, ...newSelectedChats]

            const mySelectedChats = allChats
                .filter(chat => chat.claimee?.id === account.id)
                .sort((a, b) => a.created.getTime() - b.created.getTime())
            const allSelectedChats = allChats.filter(chat => chat.claimee?.id !== account.id)

            const newSelectedChatsList = mySelectedChats.concat(allSelectedChats)

            if (!_.isEqual(selectedChats, newSelectedChatsList)) {
                dispatch(setSelectedChats(newSelectedChatsList))
            }

            const firstAddedIndex = newSelectedChats.length
                ? newSelectedChatsList.findIndex(chat => chat.id === newSelectedChats[newSelectedChats.length - 1].id)
                : -1

            const nextSlide = firstAddedIndex >= 0 ? Math.max(0, firstAddedIndex - numChatsOnScreen + 1) : activeSlide

            if (nextSlide !== activeSlide) {
                focusChat(firstAddedIndex)
            }

            return newSelectedChatsList
        },
        [selectedChats, numChatsOnScreen, activeSlide, account.id, dispatch, focusChat],
    )

    useEffect(() => {
        attachChats(myClaimedChats)
    }, [attachChats, myClaimedChats])

    useEffect(() => {
        if (!selectedChats.length) {
            return
        }

        const visibleChatsIds = selectedChats.slice(activeSlide, activeSlide + numChatsOnScreen).map(chat => chat.id)
        const renderedChatsIds = selectedChats
            .slice(Math.max(0, activeSlide - 1), Math.min(selectedChats.length, activeSlide + numChatsOnScreen + 1))
            .map(chat => chat.id)

        const updatedVisibilityChats = selectedChats.map(chat => ({
            ...chat,
            visible: visibleChatsIds.includes(chat.id),
            rendered: chat.rendered || renderedChatsIds.includes(chat.id),
        }))

        if (!_.isEqual(selectedChats, updatedVisibilityChats)) {
            dispatch(setSelectedChats(updatedVisibilityChats))
        }
    }, [activeSlide, selectedChats, numChatsOnScreen, dispatch])

    const popChat = (chatId: string) => {
        const chat = selectedChats.find(chat => chat.id === chatId)
        const pubnubService = PubNubService.getInstance(account.id)
        if (chat) {
            pubnubService.unsubscribe([chat])
        }
        dispatch(setSelectedChats(selectedChats.filter(chat => chat.id !== chatId)))
    }

    return (
        <div className={moduleName}>
            <Grid container={true} spacing={1} className={`${moduleName}__grid-container`}>
                <Grid item={true} xs={2} className={`${moduleName}__grid-item`}>
                    <ChatList myClaimedChats={myClaimedChats} attachChats={attachChats} scrollToChat={focusChat} />
                </Grid>

                <Grid item={true} xs={10} className={`${moduleName}__grid-item`}>
                    <div className={`${moduleName}__tiles-container`}>
                        <Slider
                            ref={slider as React.RefObject<any>}
                            accessibility={false}
                            slidesToShow={Math.min(numChatsOnScreen, selectedChats.length)}
                            slidesToScroll={1}
                            infinite={false}
                            arrows={true}
                            touchMove={false}
                            draggable={false}
                            afterChange={setActiveCarouselSlide}
                        >
                            {selectedChats.map((chat, index) => {
                                const selectedChat = selectedChats.find(c => c.id === chat.id)
                                const webCode = webCodes[chat.practice.id]?.filter(wc => wc.key === chat.propertyId)[0]

                                return (
                                    <div
                                        key={chat.id}
                                        className={`${moduleName}__tile`}
                                        onClick={() => focusChat(index)}
                                    >
                                        {selectedChat?.rendered && (
                                            <ChatTile
                                                selectedChat={selectedChat}
                                                isPatientOnline={selectedChat.isPatientOnline}
                                                isVisible={selectedChat.visible}
                                                webCode={webCode}
                                                focused={focusedChatIndex === index}
                                                key={selectedChat.id}
                                                onLeaveChat={popChat}
                                            />
                                        )}
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                </Grid>
            </Grid>
            <Modal className="pst-modal disconnect-modal" open={!isNetworkUp}>
                <div className="contents">
                    <DisconnectInterface />
                </div>
            </Modal>
        </div>
    )
}

export default ChatCenter
